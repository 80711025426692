import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PATH_SELECTION_EXPERT_DASHBOARD, PATH_SELECTION_EXPERT_EVALUATION } from '../../../const/routes.const'
import 
  SelectionExpertDashboardContainer 
from 
  '../../../containers/CRM/SelectionExpertCRM/SelectionExpertDashboard.container'
import 
  SelectionExpertEvaluationContainer 
from 
  '../../../containers/CRM/SelectionExpertCRM/SelectionExpertEvaluation.container'
import SelectionExpertCRMLayout from './SelectionExpertCRMLayout/SelectionExpertCRMLayout.component'

class SelectionExpertCRM extends React.Component {
  render(): JSX.Element {
    return (
      <Switch>
        <Route
          path={PATH_SELECTION_EXPERT_DASHBOARD}
          exact={true}
        >
          <SelectionExpertCRMLayout>
            <SelectionExpertDashboardContainer />
          </SelectionExpertCRMLayout>
        </Route>

        <Route
          path={PATH_SELECTION_EXPERT_EVALUATION}
          exact={true}
        >
          <SelectionExpertEvaluationContainer />
        </Route>

        <Redirect to={PATH_SELECTION_EXPERT_DASHBOARD} />
      </Switch>
    )
  }
}

export default SelectionExpertCRM