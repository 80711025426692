import React from 'react'
import { ReactComponent as Doubletapp } from '../../../assets/icons/doubletapp.svg'
import { ReactComponent as DoubletappWhite } from '../../../assets/icons/doubletapp--white.svg'
import { ReactComponent as AppStore } from '../../../assets/icons/appstore.svg'
import { ReactComponent as GooglePlay } from '../../../assets/icons/google-play.svg'
import { ReactComponent as VK } from '../../../assets/icons/vk-icon.svg'
import { ReactComponent as FB } from '../../../assets/icons/facebook-icon.svg'
import { ReactComponent as Instagram } from '../../../assets/icons/instagram-icon.svg'
import { ReactComponent as Telegram } from '../../../assets/icons/telegram-icon.svg'
import classNames from 'classnames'

import './Footer.style.sass'

interface PropTypes {
  isWhite?: boolean;
}

const block = 'footer'

export default class Footer extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={classNames(
        block,
        this.props.isWhite && `${block}--white`
      )}>
        <div className="container row row--space-between">
          <div className={`${block}__left`}>
            <div className={`${block}__press-title`}>
              ПРЕСС-АТТАШЕ:
            </div>
            <a
              className={classNames(
                `${block}__text`,
                `${block}__text--underlined`,
                'margin margin--top-xs'
              )}
              href="mailto:press@uralmusicnight.ru"
              target="_blank"
              rel="noopener noreferrer"
            >
                press@uralmusicnight.ru
            </a>
            <div className={`${block}__press-text`}>
              Проект реализуется с использованием гранта Президента РФ на развитие гражданского общества, 
              предоставленного Фондом президентских грантов.
            </div>
          </div>
          <div className={`${block}__middle`}>
            <div className={`${block}__logo`}></div>
            <a
              href="https://doubletapp.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className={`${block}__doubletapp ${block}__text ${block}__text--underlined`}
            >
              Сделано в
              {this.props.isWhite ? (
                <Doubletapp width="125" height="25" />
              ) : (
                <DoubletappWhite width="125" height="25" />
              )}
            </a>
          </div>
          <div className={`${block}__right`}>
            <div className={`${block}__social`}>
              <a 
                href="https://vk.com/uralmusicnight"
                target="_blank"
                rel="noopener noreferrer"
                className={`${block}__social-item`}
              >
                <VK 
                  fill={this.props.isWhite ? "fff" : "#000"} 
                  width="20"
                  height="20"
                />
              </a>
              <a
                href="https://www.facebook.com/uralmusicnight"
                target="_blank"
                rel="noopener noreferrer"
                className={`${block}__social-item`}
              >
                <FB 
                  fill={this.props.isWhite ? "#fff" : "#000"} 
                  width="19"
                  height="19"
                />
              </a>
              <a
                href="https://www.instagram.com/uralmusicnight/"
                target="_blank"
                rel="noopener noreferrer"
                className={`${block}__social-item`}
              >
                <Instagram 
                  fill={this.props.isWhite ? "#fff" : "000"} 
                  width="18"
                  height="18"
                />
              </a>
              <a
                href="https://t.me/uralmusicnight"
                target="_blank"
                rel="noopener noreferrer"
                className={`${block}__social-item ${block}__social-item--telegram`}
              >
                <Telegram 
                  fill={this.props.isWhite ? "#fff" : "000"} 
                  width="15"
                  height="15"
                />
              </a>
            </div>
            <div className={`${block}__text`}>
              Скачивайте наше приложение:
            </div>
            <div className={`${block}__stores`}>
              <a 
                href="https://itunes.apple.com/ru/app/ural-music-night/id1377024736?mt=8"
                target="_blank"
                rel="noopener noreferrer"
                className={`${block}__stores-item ${block}__stores-item--apple`}
              >
                <AppStore />
              </a>
              <a 
                href="https://play.google.com/store/apps/details?id=ru.doubletapp.umn&amp;hl=ru"
                target="_blank"
                rel="noopener noreferrer"
                className={`${block}__stores-item ${block}__stores-item--apple`}
              >
                <GooglePlay />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}