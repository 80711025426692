import React, { FC } from 'react'
import { 
  SelectionExpertDashboardGetResponse 
} from '../../../../models/SelectionExpertsModels/selectionExpertDashboard.model'
import Button from '../../../Button/Button.component'
import LoaderComponent from '../../../common/Loader/Loader.component'
import CrmListItemComponent from '../../AdminCRM/CrmListItem/CrmListItem.component'

import './style.sass'

interface Props {
  isLoading: boolean;
  data?: SelectionExpertDashboardGetResponse;
  onEvaluateClick: () => void;
}

const block = "selection-expert-dashboard"

const SelectionExpertDashboardComponent: FC<Props> = (props) => {
  const { isLoading, data, onEvaluateClick } = props

  return (
    <div className={block}>
      <div className="container">
        {isLoading && !data ? (
          <LoaderComponent width={100} height={100} />
        ) : (
          <div className={`${block}__content`}>
            <div className={`${block}__title`}>
              ОЦЕНКА АРТИСТОВ
            </div>
            <div className={`${block}__description`}>
              Для того, чтобы попасть на фестиваль Ural Music Night, 
              каждый артист должен пройти этап оценки экспертами. 
              Каждую заявку должны оценить три эксперта. Из этого складывается итоговый балл музыканта. 
              После выставления оценок экспертами система автоматическим образом 
              выстраивает общий рейтинг музыкальных коллективов по направлениям. <br/>
              Надеемся, что вы сможете открыть для себя интересные коллективы. Приятного прослушивания.
            </div>
            {data && data.remainsEvaluated > 0 && (
              <div className={`${block}__button`}>
                <Button
                  onClick={onEvaluateClick}
                  text={'Приступить к оценке'}
                  background="purple"
                />
              </div>
            )}
            
            {!data?.alreadyEvaluated ? (
              <div className={`${block}__no-artists-evaluated`}>
                Вы пока не оценили ни одного музыканта
              </div>
            ) : (
              <>
              {data?.alreadyEvaluated && data?.remainsEvaluated && (
                <div className={`${block}__rest-evaluated`}>
                  Осталось оценить {data.alreadyEvaluated} / {data.alreadyEvaluated + data.remainsEvaluated}
                </div>
              )}
              
              <div className={`${block}__evaluated`}>
                <div className={`${block}__title`}>
                  РАНЕЕ ОЦЕНЕННЫЕ
                </div>
                <div className={`${block}__evaluated-list`}>
                  {data.items.map(item => (
                    <div className={`${block}__evaluated-list-item`} key={item.id}>
                      <CrmListItemComponent image={item.avatar.urlSizeS} title={item.name} rating={item.ratingSum} />
                    </div>
                  ))}
                </div>
              </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectionExpertDashboardComponent