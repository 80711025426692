import React from 'react'
import InputComponent from '../../common/Input/Input.component'
import GenreRadioButtonComponent from '../../common/GenreRadioButton/GenreRadioButton.component'
import { Field, withTypes } from 'react-final-form'
import {
  genreRadioButtonsData,
  GenreRadioButtonsData,
  projectInSocial,
  memberSocial
} from './data.const'
import TextAreaComponent from '../../common/TextArea/TextArea.component'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import classNames from 'classnames'
import AddMemberButtonComponent from './AddMemberButton/AddMemberButton.component'
import { 
  ArtistFormSaveRequest, 
  CommentModel,
  artistFormStatuses,
  ArtistFormFields
} from '../../../../models/ArtistFormModels/artistForm.model'
import UploadFileContainer from '../../../../containers/CRM/common/UploadFile.container'
import DeleteMemberButtonComponent from './DeleteMemberButton/DeleteMemberButton.component'
import CheckboxComponent from '../../common/Checkbox/Checkbox.component'
import { ReactComponent as Loader } from '../../../../assets/icons/loading.svg'
import { ReactComponent as Doc } from '../../../../assets/icons/doc.svg'
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg'
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye-icon.svg'
import Button from '../../../Button/Button.component'
import InputLabelComponent from './InputLabel/InputLabel.component'
import { fieldRequired, composeValidators, incorrectBirthdate } from '../../../../const/validators.const'
import DocumentUploadContainer from '../../../../containers/CRM/common/DocumentUpload/DocumentUpload.container'
import { Element, scroller } from 'react-scroll'
import AdminApproveSidebarComponent from '../../AdminCRM/AdminApproveSidebar'
import shallowequal from 'shallowequal'
import { RequestError } from '../../../../models/common/error.model'
import { createNumberMask } from 'text-mask-addons'
import { ValidationErrors } from 'final-form'
import ErrorLabelComponent from '../../common/ErrorLabel/ErrorLabel.component'
import Example1 from '../../../../assets/files/tech rider example 1.docx'
import Example2 from '../../../../assets/files/tech rider example 2 (stage plan).doc'

import './ArtistForm.style.sass'
import { UserModel } from '../../../../models/common/user.model'
import { getUserRole } from '../../../../utils/localStorage'
import { sidebarTabs } from '../../AdminCRM/AdminApproveSidebar/AdminApproveSidebar.component'

interface CheckboxesData {
  name: string;
  isChecked: boolean;
}

interface PropTypes {
  onSubmit: (values: ArtistFormFields, isApply?: boolean) => void;
  isLoading: boolean;
  isSubmitEnabled?: boolean;
  agreeCheckboxesData?: CheckboxesData[];
  formInitialValues?: ArtistFormSaveRequest;
  showValidationErrors: boolean;
  showErrors: () => void;
  formId?: number;
  onModerate: (values: ArtistFormFields) => void;
  onApprove: (values: ArtistFormFields) => void;
  onCheckForm: (values: ArtistFormFields) => void;
  isModerateActive: boolean;
  comments?: CommentModel[];
  formStatus: artistFormStatuses;
  isFirstEdit: boolean;
  isFormApplyLoading: boolean;
  isModerateLoading: boolean;
  isApproveLoading: boolean;
  isCheckLoading: boolean;
  submittionErrors?: RequestError[];
  userData?: UserModel;
}

interface State {
  isHintsVisible: boolean;
  sidebarActiveTabIndex: number;
}

const { Form } = withTypes<ArtistFormFields>()
const block = 'artist-form'

export default class ArtistForm extends React.Component<PropTypes, State> {
  private formValues?: ArtistFormSaveRequest;
  private lastSubmitValues?: ArtistFormSaveRequest;
  private timeOut?: number;

  state = {
    isHintsVisible: true,
    sidebarActiveTabIndex: sidebarTabs['hints'].index
  }

  componentDidMount(): void {
    const userRole = getUserRole()
    if (userRole === 'ARTIST' && this.props.isFirstEdit) {
      this.timeOut = window.setTimeout(this.autoSaveInterval, 1000 * 30)
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeOut)
  }

  componentDidUpdate = (prevProps: PropTypes): void => {
    if (!this.props.isLoading && this.props.formInitialValues) {
      this.lastSubmitValues = this.props.formInitialValues
    }
    
    if (!prevProps.comments?.length && this.props.comments?.length) {
      this.setState({
        isHintsVisible: false,
        sidebarActiveTabIndex: sidebarTabs['comments'].index
      })
    }
  }

  autoSaveInterval = (): void => {
    if (!shallowequal(this.formValues, this.lastSubmitValues) && this.formValues) {
      this.props.onSubmit(this.formValues, false)
      this.lastSubmitValues = this.formValues
    }
    this.timeOut = window.setTimeout(this.autoSaveInterval, 1000 * 30)
  }

  onModerate = (): void => {
    this.formValues && this.props.onModerate(this.formValues)
  }

  onCheckForm = (): void => {
    this.formValues && this.props.onCheckForm(this.formValues)
  }

  scrollToFirstError = (errors: ValidationErrors): void => {
    const errorFields = Object.keys(errors)
    
    if (errorFields[0] === 'membersSocialErrors' && errors.membersSocialErrors) {
      const errorMembersIndex = errors.membersSocialErrors.findIndex(Boolean)
      scroller.scrollTo(`member-${errorMembersIndex}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -230
      })

      return
    }
    
    if (errorFields[0] === 'members') {
      const errorMembersIndex = errors.members.findIndex(Boolean)
      scroller.scrollTo(`member-${errorMembersIndex}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -230
      })

      return
    }

    if (errorFields[0] === 'techRiders') {
      scroller.scrollTo('tech-riders', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -230
      })

      return
    }

    scroller.scrollTo(errorFields[0], {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -230
    })
  }

  getUsername(): string {
    const { userData } = this.props
    
    if (userData?.crmRole && userData?.email) {
      return `${userData.crmRole}_${userData?.email}`
    } else {
      return `${getUserRole()}`
    }
  }

  render(): JSX.Element {
    const { 
      onSubmit, 
      isSubmitEnabled, 
      isLoading, 
      formInitialValues, 
      agreeCheckboxesData,
      isModerateActive,
      comments,
      formStatus,
      isFormApplyLoading,
      isModerateLoading,
      isApproveLoading,
      isCheckLoading,
      submittionErrors
    } = this.props
    
    const userRole = getUserRole()
    const isFormReadonly = userRole === 'EXPERT'
    
    return (
      <div 
        className={classNames(
          `${block}`,
          (this.state.isHintsVisible || isFormReadonly) && `${block}--active-hints`
        )}
      >
        {isLoading && !formInitialValues ? (
          <div className={`${block}__loader`}><Loader fill="#933ec5" width="100" height="100" /></div>
        ) : (
          <>
          <Form
            onSubmit={(values): void => onSubmit(values, true)}
            mutators={{
              ...arrayMutators
            }}
            initialValues={formInitialValues}
            validate={(values: ArtistFormSaveRequest): Record<string, string | undefined> => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const errors: Record<string, any> = {}
              const membersSocialErrors: boolean[] = []
              const members = values.members && [...values.members]
              const projectSocialNotFilled =
                !values.facebookUrl?.length
                  && !values.instagramUrl?.length
                    && !values.vkontakteUrl?.length
                      && !values.tiktokUrl?.length
                        && !values.spotifyUrl?.length ? (
                        'Одно из этих полей должно быть заполнено'
                      ) : (
                        undefined
                      )

              members?.forEach((member, index) => {
                membersSocialErrors.push(false)

                if (members && members[index]) {
                  const memberSocialNotFilled = 
                    !members[index].facebookUrl
                      && !members[index].instagramUrl
                        && !members[index].twitterUrl
                        && !members[index].vkontakteUrl ? (
                          true
                        ) : undefined

                  
                  if (memberSocialNotFilled) {
                    membersSocialErrors[index] = true
                  }
                }
              })
              
              if (membersSocialErrors.length && membersSocialErrors.filter(Boolean).length) {
                errors.membersSocialErrors = membersSocialErrors
              }
              errors.facebookUrl = projectSocialNotFilled
              errors.instagramUrl = projectSocialNotFilled
              errors.vkontakteUrl = projectSocialNotFilled
              errors.tiktokUrl = projectSocialNotFilled
              errors.spotifyUrl = projectSocialNotFilled

              if (values.techRiders && !values.techRiders.length) {
                errors.techRiders = 'Необходимо загрузить хотя бы 1 документ'
              }

              return errors
            }}
            render={({
              form: {
                mutators: { push }
              },
              values,
              touched,
              handleSubmit,
              errors,
              active,
              hasValidationErrors
            }): JSX.Element => {
              this.formValues = values
              const isSocialTouched = touched && (
                touched['facebookUrl'] || touched['instagramUrl'] || touched['vkontakteUrl'] || touched['twitterUrl']
              )

              return (
                <div className="row row--space-between">
                <form 
                  className={`${block}__form form`}
                  onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {            
                    event.preventDefault()
                    event.stopPropagation()
                    onSubmit(values, false)
                  }}
                  id="artist-form"
                >
                  <div>
                    <div className="form__title">
                      1. ОСНОВНАЯ ИНФОРМАЦИЯ
                    </div>
                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className="form__input-title">
                          * Название коллектива / имя музыканта
                          <div className="form__input-title-icon">
                            <EyeIcon />
                          </div>
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="name" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                  disabled={isFormReadonly}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Название пишется без кавычек
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>
                      
                      <div className="form__input-block margin margin--top-s">
                        <div className={classNames(
                          "form__input-title",
                          this.props.showValidationErrors && errors.genre && "form__input-title--error"
                        )}>
                          * Музыкальный жанр
                        </div>
                        <div className="form__input row">
                          {genreRadioButtonsData.map((
                            buttonData: GenreRadioButtonsData
                          ): JSX.Element => (
                            <Field
                              name="genre"
                              type="radio"
                              key={buttonData.value}
                              value={buttonData.value}
                              validate={fieldRequired}
                            >
                              {({ input }): JSX.Element => (
                                <div className={'margin margin--top-xxs'}>
                                  <Element name={input.name}>
                                    <GenreRadioButtonComponent
                                      checked={input.checked}
                                      caption={buttonData.caption}
                                      name={input.name}
                                      value={input.value}
                                      onChange={input.onChange}
                                      icon={buttonData.icon}
                                      type="radio"
                                      disabled={isFormReadonly}
                                    />
                                  </Element>
                                </div>
                              )}
                            </Field>
                          ))}
                          <div className="form__input-label">
                            <InputLabelComponent>
                              Выбрать можно только один жанр
                            </InputLabelComponent>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Музыкальное направление
                          <div className="form__input-title-icon">
                            <EyeIcon />
                          </div>
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="subgenre" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                  disabled={isFormReadonly}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Заполняется в свободной форме. Например: молодежный поп-хип-хоп
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-row margin--top-s">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Страна
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="country" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    disabled={isFormReadonly}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Город
                            <div className="form__input-title-icon">
                              <EyeIcon />
                            </div>
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="city" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    disabled={isFormReadonly}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Контактное лицо
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="contactName" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="ФИО полностью"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                  disabled={isFormReadonly}
                                />
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Ссылка на основную соцсеть контактного лица
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="contactMainSocial" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="https://"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                  disabled={isFormReadonly}
                                />
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-row margin--top-s">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Email
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="contactEmail">
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    disabled={true}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Телефон
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field
                              name="contactPhone"
                              validate={fieldRequired}
                              parse={(value): string => {
                                if (value === '') {
                                  return value
                                } else {
                                  const newValue = value.replace(/\D/g, '')
                                  return `+${newValue}`
                                }
                              }}
                            >
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="+7 999 000 00 00"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    disabled={isFormReadonly}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Описание музыкального коллектива
                          <div className="form__input-title-icon">
                            <EyeIcon />
                          </div>
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field
                            name="description"
                            validate={composeValidators(
                              fieldRequired,
                              value => value.length < 100 ? (
                                'Описание должно быть не менее 100 символов'
                              ) : undefined
                            )}
                          >
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <TextAreaComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                  disabled={isFormReadonly}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Описание должно быть не менее 100 и не более
                                    1000 символов.
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Музыкальный проект в социальных сетях
                          <div className="form__input-title-icon">
                            <EyeIcon />
                          </div>
                        </div>
                        <div className="form__input-row">
                          {projectInSocial.map((inputData, index): JSX.Element => (
                            <div className="form__input margin margin--top-s" key={inputData.name}>
                              <Field
                                name={inputData.name}
                              >
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder={inputData.placeholder}
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      icon={inputData.icon}
                                      error={isSocialTouched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                      disabled={isFormReadonly}
                                    /> 
                                    {index === 1 && (
                                      <div className="form__input-label">
                                        <InputLabelComponent
                                          isFocused={
                                            active ? (
                                              active === 'facebookUrl'
                                              || active === 'instagramUrl'
                                              || active === 'vkontakteUrl'
                                              || active === 'tiktokUrl'
                                              || active === 'spotifyUrl'
                                            ) : false
                                          }
                                        >
                                          Обязательно нужно указать как минимум одну социальную сеть
                                        </InputLabelComponent>
                                      </div>
                                    )}              
                                    
                                  </Element>
                                )}
                              </Field>
                            </div>
                          ))}
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      2. СОСТАВ КОЛЛЕКТИВА
                    </div>

                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className="form__input-title">
                          * Количество участников в коллективе
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="membersCount" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                  disabled={isFormReadonly}
                                  mask={createNumberMask({
                                    prefix: '',
                                    includeThousandsSeparator: false,
                                    allowDecimal: false,
                                    integerLimit: 3
                                  })}
                                />
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="form__form-block">

                      <FieldArray name="members">
                        {({ fields }): JSX.Element[] => (
                          fields.map((name, index): JSX.Element => {
                            const isMemberSocialTouched = touched && (
                              touched[`${name}.facebookUrl`]
                                || touched[`${name}.instagramUrl`]
                                  || touched[`${name}.vkontakteUrl`]
                                    || touched[`${name}.twitterUrl`]
                            )
                            const isMemberSocialValid = 
                              values.members && values.members[index]
                                && (values.members[index].facebookUrl
                                  || values.members[index].instagramUrl
                                    || values.members[index].twitterUrl
                                      || values.members[index].vkontakteUrl)

                            return (
                              <div key={`member-${name}`}>
                                <Element name={`member-${index}`}>
                                  <div
                                    className={classNames(
                                      index !== 0 && 'margin margin--top-s'
                                    )}
                                  >
                                    <div className="form__input-row">
                                      <div className="form__input-block">
                                        <div className="form__input-title">
                                          * ФИО
                                        </div>
                                        <div className="form__input margin margin--top-xxs">
                                          <Field name={`${name}.name`} validate={fieldRequired}>
                                            {({ input, meta }): JSX.Element => (
                                              <Element name={input.name}>
                                                <InputComponent
                                                  placeholder="Введите текст"
                                                  value={input.value}
                                                  name={input.name}
                                                  onChange={input.onChange}
                                                  error={meta.touched && meta.error}
                                                  onBlur={input.onBlur}
                                                  disabled={isFormReadonly}
                                                />
                                              </Element>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                      {
                                        fields.length && fields.length > 1 && (
                                          <DeleteMemberButtonComponent 
                                            onClick={(): void => !isFormReadonly && fields.remove(index)} 
                                          />
                                        )
                                      }

                                    </div>

                                    <div className="form__input-row margin margin--top-s">
                                      <div className="form__input-block">
                                        <div className="form__input-title">
                                          * Дата рождения
                                        </div>
                                        <div className="form__input margin margin--top-xxs">
                                          <Field
                                            name={`${name}.birthday`}
                                            validate={composeValidators(fieldRequired, incorrectBirthdate)}
                                          >
                                            {({ input, meta }): JSX.Element => (
                                              <Element name={input.name}>
                                                <InputComponent
                                                  placeholder="__.__.____"
                                                  value={input.value}
                                                  name={input.name}
                                                  onChange={input.onChange}
                                                  mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                  guide={true}
                                                  error={meta.touched && meta.error}
                                                  onBlur={input.onBlur}
                                                  disabled={isFormReadonly}
                                                />
                                              </Element>
                                            )}
                                          </Field>
                                        </div>
                                      </div>

                                      <div className="form__input-block">
                                        <div className="form__input-title">
                                          * Размер футболки
                                        </div>
                                        <div className="form__input margin margin--top-xxs">
                                          <Field name={`${name}.tShirtSize`} validate={fieldRequired}>
                                            {({ input, meta }): JSX.Element => (
                                              <Element name={input.name}>
                                                <InputComponent
                                                  placeholder="Введите текст"
                                                  value={input.value}
                                                  name={input.name}
                                                  onChange={input.onChange}
                                                  error={meta.touched && meta.error}
                                                  onBlur={input.onBlur}
                                                  onFocus={input.onFocus}
                                                  disabled={isFormReadonly}
                                                />
                                                <div className="form__input-label">
                                                  <InputLabelComponent isFocused={meta.active}>
                                                    Мы хотим подарить вам воспоминание о фестивале – футболку. 
                                                    И хотим, что бы она была вам по размеру.
                                                  </InputLabelComponent>
                                                </div>
                                              </Element>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form__input-row">
                                        {memberSocial.map((inputData): JSX.Element => (
                                          <div 
                                            className="form__input margin margin--top-s"
                                            key={inputData.name}
                                          >
                                            <Field name={`${name}.${inputData.name}`}>
                                              {({ input }): JSX.Element => (
                                                <InputComponent
                                                  placeholder={inputData.placeholder}
                                                  value={input.value}
                                                  name={input.name}
                                                  onChange={input.onChange}
                                                  icon={inputData.icon}
                                                  error={
                                                    (isMemberSocialTouched && !isMemberSocialValid) ? (
                                                      'Одно из этих полей должно быть заполнено'
                                                    ) : undefined
                                                  }
                                                  onBlur={input.onBlur}
                                                  disabled={isFormReadonly}
                                                />
                                              )}
                                            </Field>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                  <div
                                    className={classNames(
                                      'form__separator',
                                      'margin',
                                      'margin--top-s',
                                      'margin--bottom-s'
                                    )}
                                  />
                                </Element>
                              </div>
                            )
                          })
                        )}
                      </FieldArray>

                      <div className="margin margin--top-s">
                        <AddMemberButtonComponent
                          text={'Добавить участника'}
                          onClick={(): void => !isFormReadonly && push('members', undefined)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      3. МЕДИАМАТЕРИАЛЫ
                    </div>
                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className={classNames(
                          "form__input-title",
                          (this.props.showValidationErrors && (
                            errors.photoSite
                            || errors.photoAffiche
                            || errors.photoSiteHeader
                            || errors.photoSocial
                          )) && "form__input-title--error"
                        )}>
                          * Фотографии
                        </div>
                        <div className={classNames(
                          `form__images-row`,
                          `${block}__images-row`
                        )}>
                          <div className="form__input margin margin--top-xs">
                            <Field name="photoSite" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <UploadFileContainer
                                    nameForStore="photoSite"
                                    title="На сайт"
                                    fileTypes={["image/jpeg", "image/png"]}
                                    onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                    uploadedFile={values.photoSite ? values.photoSite : undefined}
                                    onFileDelete={(): void => {
                                      input.onChange(undefined)
                                    }}
                                    showFileName={false}
                                    minWidth={600}
                                    minHeight={400}
                                    disabled={isFormReadonly}
                                    userName={this.getUsername()}
                                    documentType={'site-photo'}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                          <div className="form__input margin margin--top-xs">
                            <Field name="photoSiteHeader" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <UploadFileContainer
                                    nameForStore="photoSiteHeader"
                                    title="В шапку сайта"
                                    fileTypes={["image/jpeg", "image/png"]}
                                    onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                    uploadedFile={values.photoSiteHeader ? values.photoSiteHeader : undefined}
                                    showFileName={false}
                                    minWidth={1920}
                                    minHeight={1080}
                                    onFileDelete={(): void => {
                                      input.onChange(undefined)
                                    }}
                                    disabled={isFormReadonly}
                                    userName={this.getUsername()}
                                    documentType={'site-header-photo'}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                          <div className="form__input margin margin--top-xs">
                            <Field name="photoSocial" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <UploadFileContainer
                                    nameForStore="photoSocial"
                                    title="Для соц.сетей"
                                    fileTypes={["image/jpeg", "image/png"]}
                                    onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                    uploadedFile={values.photoSocial ? values.photoSocial : undefined}
                                    showFileName={false}
                                    minWidth={1000}
                                    minHeight={1000}
                                    onFileDelete={(): void => {
                                      input.onChange(undefined)
                                    }}
                                    disabled={isFormReadonly}
                                    userName={this.getUsername()}
                                    documentType={'social-network-photo'}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                          <div className="form__input margin margin--top-xs">
                            <Field name="photoAffiche" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <UploadFileContainer
                                    nameForStore="photoAffiche"
                                    title="Для афиши"
                                    fileTypes={["image/jpeg", "image/png"]}
                                    onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                    uploadedFile={values.photoAffiche ? values.photoAffiche : undefined}
                                    showFileName={false}
                                    minWidth={800}
                                    minHeight={1200}
                                    onFileDelete={(): void => {
                                      input.onChange(undefined)
                                    }}
                                    disabled={isFormReadonly}
                                    userName={this.getUsername()}
                                    documentType={'affiche-photo'}
                                  />
                                </Element>
                              )}
                            </Field>
                            <div className="form__input-label form__input-label--align-bottom">
                              <InputLabelComponent>
                                На сайт: горизонтальная фотография - <span>600 х 400 px;</span><br/>
                                В шапку сайта: горизонтальная - <span>2880 х 1100 px;</span><br/>
                                Для соц.сетей: квадратная - <span>1000 х 1000 px;</span><br/>
                                Для афиши: вертикальная - <span>800 х 1200 px;</span>
                              </InputLabelComponent>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin margin--top-s">
                        <div className="form__input-title">
                          Ссылка на видео
                        </div>
                        <FieldArray name="videoUrls" >
                          {({ fields }): JSX.Element[] => (
                            fields.map((name, index): JSX.Element =>
                            <div className="form__input margin margin--top-xxs" key={`video-url-${name}`}>
                              <Field name={`videoUrls[${index}]`}>
                                {({ input }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="https://"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      onFocus={input.onFocus}
                                      onBlur={input.onBlur}
                                      disabled={isFormReadonly}
                                    />
                                  </Element>
                                )}
                              </Field>

                              {index === 0 && (
                                <div 
                                  className="form__input-label"
                                >
                                  <InputLabelComponent
                                    isFocused={
                                      active ? (
                                        active.indexOf('videoUrls') >= 0 
                                      ) : false
                                    }
                                  >
                                    Видео должно содержать музыкальный клип или<br/>
                                    live-видео исполнителя. В поле ввода необходимо вставить
                                    ссылку на видео из адресной строки
                                  </InputLabelComponent>
                                </div>
                              )}
                            </div>
                            )
                          )}
                        </FieldArray>
                      </div>

                      <div className="form__input-block margin margin--top-s">
                        <div className="form__input-title">
                          Ссылка на вашу музыку
                        </div>
                        <FieldArray name="audioUrls" >
                          {({ fields }): JSX.Element[] => (
                            fields.map((name, index): JSX.Element =>
                            <div className="form__input margin margin--top-xxs" key={`audio-url-${name}`}>
                              <Field name={`audioUrls[${index}]`}>
                                {({ input }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="https://"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      onFocus={input.onFocus}
                                      onBlur={input.onBlur}
                                      disabled={isFormReadonly}
                                    />
                                  </Element>
                                )}
                              </Field>

                              {index === 0 && (
                                <div 
                                  className="form__input-label"
                                >
                                  <InputLabelComponent
                                    isFocused={
                                      active ? (
                                        active.indexOf('audioUrls') >= 0 
                                      ) : false
                                    }
                                  >
                                    Допускаются ссылки с Soundcloud, Яндекс.Музыки, ВКонтакте или 
                                    других стриминговых сервисов с открытым доступом.
                                  </InputLabelComponent>
                                </div>
                              )}
                            </div>
                            )
                          )}
                        </FieldArray>
                      </div>

                      <div className="form__input-block margin margin--top-s">
                        <div className={classNames(
                          "form__input-title",
                          this.props.showValidationErrors && errors.techRiders && "form__input-title--error"
                        )}>
                          * Технические райдеры
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Element name="tech-riders">
                            <FieldArray name="techRiders" validate={(valuesArray): string | false => (
                              !valuesArray?.length && 'Это обязательное поле'
                            )}>
                              {({ fields }): JSX.Element[] => (
                                fields.map((name, index): JSX.Element => (
                                  <div className="form__document" key={`document-${name}`}>
                                    <div className="form__document-img">
                                      <Doc fill="#000" />
                                    </div>
                                    <a
                                      href={values.techRiders ? values.techRiders[index]?.url : ''}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="form__document-link"
                                    >
                                      {values.techRiders && values.techRiders[index]?.name}
                                    </a>
                                    <div 
                                      className="form__document-remove"
                                      onClick={(): string | false => !isFormReadonly && fields.remove(index)}
                                    >
                                      <Remove width="15" height="15" />
                                    </div>
                                  </div>
                                )
                              ))}
                            </FieldArray>
                          </Element>
                          
                          <div className="margin margin--top-xs">
                            <DocumentUploadContainer 
                              nameForStore={'rider-upload'} 
                              fileTypes={[".txt", ".doc", ".pdf", ".jpeg", ".jpg", ".png", ".docx"]}
                              onFileUpload={(uploadedFile): void => push('techRiders', uploadedFile)}
                              disabled={isFormReadonly}
                            />
                          </div>
                          
                          <div className="form__input-label">
                            <InputLabelComponent>
                              Допустимые форматы: txt, doc, pdf, jpeg<br/>
                              Примеры:<br/>
                              <span><a href={Example1}>Пример техрайдера 1</a></span><br/>
                              <span><a href={Example2}>Пример техрайдера 2 (стейдж план)</a></span>
                            </InputLabelComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      4. ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ
                    </div>
                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className="form__input-title">
                          Укажите несколько СМИ в вашем городе, которые могли бы анонсировать ваше участие в фестивале
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="localMediaUrl">
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  disabled={isFormReadonly}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Можно указать ссылки на новостные порталы, нишевые сми,
                                    музыкальные и другие популярные паблики в соцсетях вашего города.
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-block margin margin--top-s">
                        <div className="form__input-title">
                          * Ссылка на промо-пост с анонсом участия в фестивале UMN
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="promoPostUrl" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                  disabled={isFormReadonly}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Пост в соцсетях должен содержать фотографию, анонс мероприятия 
                                    и хештег <span>#uralmusicnight2021</span>
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {userRole === 'ARTIST' && (
                    <div className={`${block}__submit-area`}>
                      {agreeCheckboxesData?.map((checkboxData, index) => (
                        <div
                          className={classNames(
                            index !== 0 && 'margin margin--top-s'
                          )}
                          key={`agree-checkbox-${checkboxData.name}`}
                        >
                          <CheckboxComponent
                            name={checkboxData.name}
                            defaultIsChecked={checkboxData.isChecked}
                          >
                            {index === 0 ? (
                              <>
                                Я согласен на предоставление организаторам фестиваля доступа к
                                социальной сети ВКонтакте для рассылки по подписчикам;
                              </>
                            ) : (
                              <>
                                Я осведомлен с {' '}
                                <a
                                  href="https://uralmusicnight.ru/terms"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  правилами и условиями
                                </a> фестиваля.
                              </>
                            )}
                          </CheckboxComponent>
                        </div>
                      ))}

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          Ваш комментарий:
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field
                            name="comment"
                          >
                            {({ input, meta }): JSX.Element => (
                              <TextAreaComponent
                                placeholder="Введите комментарий"
                                value={input.value}
                                name={input.name}
                                onChange={input.onChange}
                                error={meta.touched && meta.error}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                disabled={isFormReadonly}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="margin margin--top-m">
                        <Button
                          text="Подать заявку"
                          background="purple"
                          isDisabled={!isSubmitEnabled}
                          onClick={(): void => {
                            this.scrollToFirstError(errors)
                            this.props.showErrors()
                            isSubmitEnabled && handleSubmit()
                          }}
                          isLoading={isFormApplyLoading}
                        />
                      </div>

                      {submittionErrors && submittionErrors.length && (
                        submittionErrors.map((error, index: number) => (
                          <ErrorLabelComponent text={error.text} key={`${error.code}-${index}`} />
                        ))
                      )}
                    </div>
                  )}

                  {formStatus === 'EDITING' && userRole === 'ADMIN' && (
                    <div className={`${block}__submit-area`}>
                      <div className="margin margin--top-xxs">
                        <Button
                          text="Одобрить заявку"
                          background="purple"
                          onClick={(): void => {
                            this.scrollToFirstError(errors)
                            this.props.showErrors()
                            handleSubmit()
                          }}
                          isLoading={isApproveLoading}
                        />
                      </div>
                      {submittionErrors && submittionErrors.length && (
                        submittionErrors.map((error, index: number) => (
                          <ErrorLabelComponent text={error.text} key={`${error.code}-${index}`} />
                        ))
                      )}
                    </div>
                  )}
                </form>
                
                {!isFormReadonly && (
                  <div className={`margin margin--top-xl ${block}__sidebar`}>
                    <AdminApproveSidebarComponent
                      onModerate={this.onModerate}
                      onApprove={(): void => {
                        this.formValues && this.props.onApprove(this.formValues)
                      }} 
                      isModerateActive={isModerateActive}
                      isApproveActive={!hasValidationErrors}
                      comments={comments}
                      onCheckForm={this.onCheckForm}
                      formStatus={formStatus}
                      formData={values}
                      commentInputTitle={'КОММЕНТАРИЙ ДЛЯ АРТИСТА'}
                      isModerateLoading={isModerateLoading}
                      isApproveLoading={isApproveLoading}
                      isCheckLoading={isCheckLoading}
                      submittionErrors={submittionErrors}
                      onTabChange={(index): void => {
                        this.setState({
                          isHintsVisible: index === 1,
                          sidebarActiveTabIndex: index
                        })
                      }}
                      activeTabIndex={this.state.sidebarActiveTabIndex}
                    />
                  </div>
                )}
                
                </div>
              )}
            }
          />
          </>
        )}
      </div>
    )
  }
}
