import React from 'react'
import ScenesListComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/ScenesList'
import { getScenesList } from '../../../../../services/CRM/AdminCRM/scenes/scenesList.service'
import { scenesListStore } from '../../../../../stores/CRM/AdminCRM/scenes/scenesList.store'
import { observer } from 'mobx-react'
import { createScene } from '../../../../../services/CRM/AdminCRM/scenes/createScene.service'
import { createSceneStore } from '../../../../../stores/CRM/AdminCRM/scenes/createScene.store'
import { sceneModerationStore } from '../../../../../stores/CRM/AdminCRM/scenes/sceneModeration.store'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PATH_PLATFORM_MODERATE, PATH_CRM_SCENES_NEW } from '../../../../../const/routes.const'

interface State {
  newSceneName: string;
  isModalVisible: boolean;
}

@observer
class ScenesListContainer extends React.Component<RouteComponentProps<{platformId: string}>, State> {
  state = {
    newSceneName: '',
    isModalVisible: false
  };

  componentDidMount(): void {
    const platformId = parseInt(this.props.match.params.platformId)

    if (isNaN(platformId)) {
      this.props.history.replace(PATH_CRM_SCENES_NEW)

      return
    }

    getScenesList(platformId)
  }

  componentWillUnmount(): void {
    scenesListStore.clearData()
  }

  setSceneName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      newSceneName: event.target.value
    })
  }

  toggleModal = (): void => {
    this.setState((prevState: State) => ({
      isModalVisible: !prevState.isModalVisible
    }))
  }

  createScene = (): void => {
    const platformId = parseInt(this.props.match.params.platformId)

    createScene({name: this.state.newSceneName}, platformId).then(() => {
      this.toggleModal()
    })
    
  }

  onSceneClick = (sceneId: number, name: string): void => {
    sceneModerationStore.setSceneName(name)
    const platformId = parseInt(this.props.match.params.platformId)

    this.props.history.push(`${PATH_PLATFORM_MODERATE}/${platformId}/scenes/${sceneId}`)
  }

  render(): JSX.Element {
    return (
      <ScenesListComponent 
        scenes={scenesListStore.scenesList}
        onSceneAddClick={this.toggleModal}
        onSceneCreate={this.createScene}
        isModalVisible={this.state.isModalVisible}
        isScenesLoading={scenesListStore.isLoading}
        newSceneName={this.state.newSceneName}
        onSceneNameChange={this.setSceneName}
        isSceneCreating={createSceneStore.isLoading}
        onModalToggle={this.toggleModal}
        onSceneClick={this.onSceneClick}
      />
    )
  }
}

export default withRouter(ScenesListContainer)