import React from 'react'
import { 
  PerfomanceSaveRequest, 
  TimelineGetResponse, 
  IntervalModel, 
  PerformanceStatuses,
  ScheduleTimelineItem
} from '../../../../../models/ScenesModels/performances.model'
import { ReactComponent as Done } from '../../../../../assets/icons/done.svg'
import { ReactComponent as Remove } from '../../../../../assets/icons/remove.svg'
import { ReactComponent as Eye } from '../../../../../assets/icons/eye.svg'
import { ReactComponent as Pencil } from '../../../../../assets/icons/pencil.svg'
import SelectComponent from '../../../common/Select/Select.component'
import { withTypes, Field } from 'react-final-form'
import { fieldRequired } from '../../../../../const/validators.const'
import TimePicker from 'rc-time-picker'
import classNames from 'classnames'
import moment, { Moment } from 'moment'
import Button from '../../../../Button/Button.component'
import LabelComponent from '../../../common/Label/Label.component'
import { ValidationErrors } from 'final-form'
import LoaderComponent from '../../../../common/Loader/Loader.component'

import "slick-carousel/slick/slick.css"
import './Performance.style.sass'

interface PropTypes {
  formInitialValues?: PerfomanceSaveRequest;
  timelineData?: TimelineGetResponse;
  onSubmit: (values: PerfomanceSaveRequest) => void;
  onEditClick: () => void;
  onEyeClick?: () => void;
  name: string;
  image: string;
  isTimelineVisible: boolean;
  isTimelineLoading: boolean;
  isSubmitLoading: boolean;
  hasReset?: boolean;
  type: 'artist' | 'perfomance';
  onApprove?: (values: PerfomanceSaveRequest) => void;
  onDelete?: () => void;
  isApproveLoading?: boolean;
  isDeleteLoading?: boolean;
  status?: PerformanceStatuses;
}

const { Form } = withTypes<PerfomanceSaveRequest>()
const timelineDays = ['06-24', '06-25', '06-26']
const lastDayMaxTime = '05:00:00'
const lastDay = timelineDays[timelineDays.length -1]

const block = "performance"

const errorsToShow: {[key: string]: string} = {
  tooLate: 
    `${moment(lastDay, 'MM-DD').format('DD.MM')}
    фестиваль работает до 05:00`,
  soundcheckFirst: 'Выступление должно идти после саундчека'
}

const performanceStatuses: {[key in PerformanceStatuses]: {
  title: string;
  color: "red" | "yellow" | "green";
}} = {
  PENDING: {
    title: 'В ожидании ответа артиста',
    color: 'yellow'
  },
  APPROVED: {
    title: 'Участие одобрено',
    color: 'green'
  },
  DECLINED: {
    title: 'Участие отклонено',
    color: 'red'
  }
}

export default class PerformanceComponent extends React.Component<PropTypes> {
  constructor(props: PropTypes) {
    super(props)
  }

  validateValues = (values: PerfomanceSaveRequest): ValidationErrors => {
    const errors: ValidationErrors = {}
    const timelineData = this.props.timelineData
    const performanceEndDate = moment(values.endTime, 'HH:mm').isSameOrBefore(moment(values.startTime, 'HH:mm')) ? (
      moment(values.date, 'MM-DD').add(1, 'days').format('MM-DD')
    ) : (
      values.date
    )

    const soundcheckEndDate = 
      moment(values.soundcheckEndTime, 'HH:mm').isSameOrBefore(moment(values.soundcheckStartTime, 'HH:mm')) ? (
        moment(values.soundcheckDate, 'MM-DD').add(1, 'days').format('MM-DD')
      ) : (
        values.soundcheckDate
      )
    
    const performanceStartDateTime = moment(`${values.date} ${values.startTime}`, 'MM-DD HH:mm:ss')
    const performanceEndDateTime = moment(`${performanceEndDate} ${values.endTime}`, 'MM-DD HH:mm:ss')
    const soundCheckStartDateTime = moment(`${values.soundcheckDate} ${values.soundcheckStartTime}`, 'MM-DD HH:mm:ss')
    const soundCheckEndDateTime = moment(`${soundcheckEndDate} ${values.soundcheckEndTime}`, 'MM-DD HH:mm:ss')

    if (lastDay === values.date) {
      if (moment(values.startTime, 'HH:mm:ss').isAfter(moment(lastDayMaxTime, 'HH:mm:ss'))) {
        errors.startTime = errorsToShow.tooLate
      } 

      if (moment(values.endTime, 'HH:mm:ss').isAfter(moment(lastDayMaxTime, 'HH:mm:ss')) 
          || moment(performanceEndDate, 'MM-DD').isAfter(moment(values.date, 'MM-DD'))
      ) {
        errors.endTime = errorsToShow.tooLate
      }
    }

    if (performanceEndDate === lastDay) {
      if (moment(values.endTime, 'HH:mm:ss').isAfter(moment(lastDayMaxTime, 'HH:mm:ss'))) {
        errors.endTime = errorsToShow.tooLate
      }
    }

    if (lastDay === values.soundcheckDate) {
      if (moment(values.soundcheckStartTime, 'HH:mm:ss').isAfter(moment(lastDayMaxTime, 'HH:mm:ss'))) {
        errors.soundcheckStartTime = errorsToShow.tooLate
      } 

      if (moment(values.soundcheckEndTime, 'HH:mm:ss').isAfter(moment(lastDayMaxTime, 'HH:mm:ss')) 
          || moment(soundcheckEndDate, 'MM-DD').isAfter(moment(values.soundcheckDate, 'MM-DD'))
      ) {
        errors.soundcheckEndTime = errorsToShow.tooLate
      }
    }

    if (soundcheckEndDate === lastDay) {
      if (moment(values.endTime, 'HH:mm:ss').isAfter(moment(lastDayMaxTime, 'HH:mm:ss'))) {
        errors.soundcheckEndTime = errorsToShow.tooLate
      }
    }

    if (values.startTime && values.soundcheckEndTime && values.date && values.soundcheckDate
      && soundCheckEndDateTime.isAfter(performanceStartDateTime)
    ) {
      errors.soundcheckEndTime = errorsToShow.soundcheckFirst
      errors.startTime = errorsToShow.soundcheckFirst
    }

    timelineData?.artistBusyIntervals.forEach((interval) => {
      const error = 'Артист занят в это время.'
      if (this.getIsArtistBusy(interval, soundCheckStartDateTime)) {
        errors.soundcheckStartTime = error
      }

      if (this.getIsArtistBusy(interval, soundCheckEndDateTime)) {
        errors.soundcheckEndTime = error
      }

      if (this.getIsArtistBusy(interval, performanceStartDateTime)) {
        errors.startTime = error
      }

      if (this.getIsArtistBusy(interval, performanceEndDateTime)) {
        errors.endTime = error
      }
    })

    timelineData?.placeBusyIntervals.forEach((placeBusyInterval) => {
      const error = 'Сцена занята в это время.'
      if (this.getIsSceneBusy(placeBusyInterval, soundCheckStartDateTime, 'startTime')) {
        errors.soundcheckStartTime = error
      }

      if (this.getIsSceneBusy(placeBusyInterval, soundCheckEndDateTime, 'endTime')) {
        errors.soundcheckEndTime = error
      }

      if (this.getIsSceneBusy(placeBusyInterval, performanceStartDateTime, 'startTime')) {
        errors.startTime = error
      }

      if (this.getIsSceneBusy(placeBusyInterval, performanceEndDateTime, 'endTime')) {
        errors.endTime = error
      }
    })

    return errors
  }

  getIsArtistBusy = (interval: IntervalModel, dateTime: Moment): boolean => {
    if (dateTime.isSameOrAfter(interval.start)
      && dateTime.isSameOrBefore(interval.end)) {
        return true
    } else {
      return false
    }
  }

  getIsSceneBusy = (interval: IntervalModel, dateTime: Moment, type: 'startTime' | 'endTime'): boolean => {
    if (type === 'startTime') {
      if (dateTime.isSameOrAfter(interval.start)
        && dateTime.isBefore(interval.end)) {
          return true
      } else {
        return false
      }
    }

    if (type === 'endTime') {
      if (dateTime.isAfter(interval.start)
        && dateTime.isSameOrBefore(interval.end)) {
          return true
      } else {
        return false
      }
    }

    return false
  }

  getTypeLabel(type: "performance" | "soundcheck"): string {
    switch(type) {
      case "performance":
        return 'Выступление'
      case "soundcheck":
        return 'Саундчек'
    }
  }

  render(): JSX.Element {
    const { 
      onSubmit, 
      formInitialValues, 
      name, 
      image,
      isTimelineVisible,
      isTimelineLoading,
      isSubmitLoading,
      hasReset,
      type,
      onApprove,
      onDelete,
      isApproveLoading,
      isDeleteLoading,
      status,
      onEditClick,
      onEyeClick,
      timelineData
    } = this.props

    return (
      <div className={block}>
        <Form
          onSubmit={(values): void => onSubmit(values)}
          initialValues={formInitialValues}
          validate={this.validateValues}
          render={({
            values,
            handleSubmit,
            pristine,
            form,
            hasValidationErrors
          }): JSX.Element => (
            <>
            <div className={`${block}__header-wrapper`}>
              <div 
                className={classNames(
                  `${block}__header`,
                  isTimelineVisible && `${block}__header--active`,
                  type === 'perfomance' && `${block}__header--performance`
                )}
              >
                <div className={`${block}__image`} style={{backgroundImage: `url(${image})`}} />
                <div className={`${block}__header-body`}>
                  {formInitialValues && (
                    <div className={`${block}__header-info row row--space-between row--align-top`}>
                      <div className={`${block}__time`}>
                        {moment(formInitialValues.startTime, 'HH:mm:ss').format('HH:mm')}
                        {' - '}
                        {moment(formInitialValues.endTime, 'HH:mm:ss').format('HH:mm')}
                      </div>
                      {status && (
                        <div className={`${block}__status`}>
                          {performanceStatuses[status].title}
                          <div className={classNames(
                            `${block}__status-icon`,
                            `${block}__status-icon--${performanceStatuses[status].color}` 
                          )} />
                        </div>
                      )}
                    </div>
                  )}

                  <div className={'row row--space-between row--align-center'}>
                    <div className={`${block}__name`}>
                      {name}
                    </div>

                    <div className={`${block}__header-buttons`}>
                      {onEyeClick && (
                        <div
                          className={classNames(
                            `${block}__header-button`,
                            type === 'perfomance' && `${block}__header-button--small`
                          )}
                          onClick={onEyeClick}
                        >
                          <Eye fill="#000"  />
                        </div>
                      )}
                      <div
                        className={classNames(
                          `${block}__header-button`,
                          isTimelineVisible && `${block}__header-button--active`,
                          type === 'perfomance' && `${block}__header-button--small`
                        )}
                        onClick={onEditClick}
                      >
                        {isTimelineVisible ? (
                          <Pencil fill="#fff" />
                        ) : (
                          <Pencil fill="#000" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {type === 'perfomance' && (
                <div className={`${block}__controls`}>
                  {status !== 'APPROVED' && (
                    <div 
                      className={classNames(
                        `${block}__control-button`,
                        (isDeleteLoading || isApproveLoading || hasValidationErrors || isSubmitLoading) && (
                          `${block}__control-button--disabled`
                        )
                      )}
                      onClick={
                        (!isSubmitLoading && !isDeleteLoading 
                          && !isApproveLoading && !hasValidationErrors && onApprove) ? (
                          (): void => onApprove(values)
                        ) : (
                          undefined
                        )}
                    >
                      <Done />
                    </div>
                  )}
                  <div 
                    className={classNames(
                      `${block}__control-button`,
                      (isDeleteLoading || isApproveLoading) && `${block}__control-button--disabled`
                    )}
                    onClick={(!isDeleteLoading && !isApproveLoading) ? onDelete : undefined}
                  >
                    <Remove width="50" height="50" />
                  </div>
                </div>
              )}
            </div>

            {isTimelineVisible && (
              <div className={`${block}__info`}>
                {isTimelineLoading ? (
                  <div className={`${block}__loader`}>
                    <LoaderComponent width={50} height={50} />
                  </div>
                ) : (

                  <>
                    <div className={`${block}__info-inputs`}>
                      <div className={`row row--align-center row--space-between`}>
                        <div className={`${block}__input-title`}>
                          Саундчек
                        </div>

                        <div className={`row row--align-center row--space-between`}>
                          <div className={`${block}__input ${block}__input--date`}>
                            <Field name="soundcheckDate" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <>
                                <SelectComponent
                                  value={input.value}
                                  onChange={(value): void => {
                                    input.onChange(value)
                                  }}
                                  placeholder="День"
                                  options={timelineDays.map(day => ({
                                    label: moment(day, 'MM-DD').format('DD.MM'),
                                    value: day
                                  }))}
                                  name={input.name}
                                  isSmall={true}
                                  error={meta.touched && meta.error}
                                />
                                {meta.touched && meta.error && !meta.active && (
                                  <div className={`${block}__slide-label`}>
                                    <LabelComponent 
                                      items={[
                                        {icon: Remove, text: meta.error}
                                      ]}
                                      type="bottom"
                                    />
                                  </div>
                                )}
                                </>
                              )}
                            </Field>
                          </div>

                          <div className={`row row--align-center`}>
                            <div className={`${block}__input`}>
                              <Field name="soundcheckStartTime" validate={fieldRequired}>
                                {({ input, meta }): JSX.Element => (
                                  <>
                                  <TimePicker
                                    onChange={(momentValue): void => {
                                      !!momentValue ? (
                                        input.onChange(`${momentValue.format('HH:mm')}:00`)
                                      ) : (
                                        input.onChange(undefined)
                                      )
                                    }}
                                    className={classNames(
                                      'form-time-picker form-time-picker--xxs',
                                      meta.touched && meta.error && 'form-time-picker--error'
                                    )}
                                    popupClassName="form-time-picker-popup"
                                    showSecond={false}
                                    minuteStep={5}
                                    value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                    onOpen={(): void => {
                                      input.onFocus()
                                    }}
                                    onClose={(): void => input.onBlur()}
                                    placeholder="__:__"
                                    focusOnOpen={true}
                                    defaultOpenValue={moment('00:00', 'HH:mm')}
                                  />
                                  {meta.touched && meta.error && !meta.active && (
                                    <div className={`${block}__slide-label`}>
                                      <LabelComponent 
                                        items={[
                                          {icon: Remove, text: meta.error}
                                        ]}
                                        type="bottom"
                                      />
                                    </div>
                                  )}
                                  
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className={`${block}__input-separator`}>-</div>
                            <div className={`${block}__input`}>
                              <Field name="soundcheckEndTime" validate={fieldRequired}>
                                {({ input, meta }): JSX.Element => (
                                  <>
                                  <TimePicker
                                    onChange={(momentValue): void => {
                                      !!momentValue ? (
                                        input.onChange(`${momentValue.format('HH:mm')}:00`)
                                      ) : (
                                        input.onChange(undefined)
                                      )
                                    }}
                                    className={classNames(
                                      'form-time-picker form-time-picker--xxs',
                                      meta.touched && meta.error && 'form-time-picker--error'
                                    )}
                                    popupClassName="form-time-picker-popup"
                                    showSecond={false}
                                    minuteStep={5}
                                    value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                    onOpen={(): void => {
                                      input.onFocus()
                                    }}
                                    onClose={(): void => input.onBlur()}
                                    placeholder="__:__"
                                    focusOnOpen={true}
                                    defaultOpenValue={moment('00:00', 'HH:mm')}
                                  />

                                  {meta.touched && meta.error && !meta.active && (
                                    <div className={`${block}__slide-label`}>
                                      <LabelComponent 
                                        items={[
                                          {icon: Remove, text: meta.error}
                                        ]}
                                        type="bottom"
                                      />
                                    </div>
                                  )}
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${block}__performance-inputs-row row row--space-between row--align-center`}>
                        <div className={`${block}__input-title`}>
                          Выступление
                        </div>
                        
                        <div className={`row row--align-center row--space-between`}>
                          <div className={`${block}__input ${block}__input--date`}>
                            <Field name="date" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <>
                                <SelectComponent
                                  value={input.value}
                                  onChange={(value): void => {
                                    input.onChange(value)
                                  }}
                                  placeholder="День"
                                  options={timelineDays.map(day => ({
                                    label: moment(day, 'MM-DD').format('DD.MM'),
                                    value: day
                                  }))}
                                  name={input.name}
                                  isSmall={true}
                                  error={meta.touched && meta.error}
                                />
                                {meta.touched && meta.error && !meta.active && (
                                  <div className={`${block}__slide-label`}>
                                    <LabelComponent 
                                      items={[
                                        {icon: Remove, text: meta.error}
                                      ]}
                                      type="bottom"
                                    />
                                  </div>
                                )}
                                </>
                              )}
                            </Field>
                          </div>

                          <div className={`row row--align-center`}>
                            <div className={`${block}__input`}>
                              <Field name="startTime" validate={fieldRequired}>
                                {({ input, meta }): JSX.Element => (
                                  <>
                                  <TimePicker
                                    onChange={(momentValue): void => {
                                      !!momentValue ? (
                                        input.onChange(`${momentValue.format('HH:mm')}:00`)
                                      ) : (
                                        input.onChange(undefined)
                                      )
                                    }}
                                    className={classNames(
                                      'form-time-picker form-time-picker--xxs',
                                      meta.touched && meta.error && 'form-time-picker--error'
                                    )}
                                    popupClassName="form-time-picker-popup"
                                    showSecond={false}
                                    minuteStep={5}
                                    value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                    onOpen={(): void => {
                                      input.onFocus()
                                    }}
                                    onClose={(): void => input.onBlur()}
                                    placeholder="__:__"
                                    focusOnOpen={true}
                                    defaultOpenValue={moment('00:00', 'HH:mm')}
                                  />

                                  {meta.touched && meta.error && !meta.active && (
                                    <div className={`${block}__slide-label`}>
                                      <LabelComponent 
                                        items={[
                                          {icon: Remove, text: meta.error}
                                        ]}
                                        type="bottom"
                                      />
                                    </div>
                                  )}
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className={`${block}__input-separator`}>-</div>
                            <div className={`${block}__input`}>
                              <Field name="endTime" validate={fieldRequired}>
                                {({ input, meta }): JSX.Element => (
                                  <>
                                  <TimePicker
                                    onChange={(momentValue): void => {
                                      !!momentValue ? (
                                        input.onChange(`${momentValue.format('HH:mm')}:00`)
                                      ) : (
                                        input.onChange(undefined)
                                      )
                                    }}
                                    className={classNames(
                                      'form-time-picker form-time-picker--xxs',
                                      meta.touched && meta.error && 'form-time-picker--error'
                                    )}
                                    popupClassName="form-time-picker-popup"
                                    showSecond={false}
                                    minuteStep={5}
                                    value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                    onOpen={(): void => {
                                      input.onFocus()
                                    }}
                                    onClose={(): void => input.onBlur()}
                                    placeholder="__:__"
                                    focusOnOpen={true}
                                    defaultOpenValue={moment('00:00', 'HH:mm')}
                                  />
                                  {meta.touched && meta.error && !meta.active && (
                                    <div className={`${block}__slide-label`}>
                                      <LabelComponent 
                                        items={[
                                          {icon: Remove, text: meta.error}
                                        ]}
                                        type="bottom"
                                      />
                                    </div>
                                  )}
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {!!timelineData?.artistTimeline.length && (
                      <div className={`${block}__timeline`}>
                        <div className={`${block}__timeline-title`}>
                          Расписание артиста:
                        </div>
                        <div className={`${block}__timeline-items`}>
                          {timelineData.artistTimeline.map(item => (
                            <div 
                              className={`${block}__timeline-item`} 
                              key={moment(item.start).format('dd/mm/yyyy HH:mm:ss')}
                            >
                              <div className={`${block}__timeline-time`}>
                                {moment(item.start).format('HH:mm')} 
                                {' '} - {' '} 
                                {moment(item.end).format('HH:mm')}
                              </div>
                              <div className={`${block}__timeline-place`}>
                                {`• ${this.getTypeLabel(item.type)} ${item.name}`}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    
                    <div className={`${block}__buttons`}>
                      {hasReset && (
                        <div className={`${block}__button`}>
                          <Button 
                            background="pale-blue"
                            onClick={(): void => {
                              form.reset(formInitialValues)
                            }}
                            text="Сбросить"
                            isDisabled={pristine || isApproveLoading}
                          />
                        </div>
                      )}

                      
                      <div className={`${block}__button ${block}__button--save`}>
                        <Button 
                          background="purple"
                          onClick={handleSubmit}
                          isDisabled={pristine || isApproveLoading}
                          text={type === 'artist' ? "Отправить заявку артисту" : "Сохранить"}
                          isLoading={isSubmitLoading}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            </>
          )}
        />
      </div>
    )
  }
}