import { api } from "../../../utils/api"
import { Serialize, Deserialize } from "cerialize"
import {
  ArtistFormSaveRequest,
  ArtistFormGetResponse,
  MemberModel, 
  ArtistFormApplyRequest
} from "../../../models/ArtistFormModels/artistForm.model"
import { 
  artistFormSaveStore, 
  artistFormGetStore, 
  artistFormApplyStore, 
  artistFormModerateStore, 
  artistFormReadStore,
  artistFormApproveStore
} from "../../../stores/CRM/ArtistCRM/artistForm.store"
import { RequestErrorResponse } from "../../../models/common/error.model"
import moment from 'moment'
import { isDateValid } from "../../../const/validators.const"
import { artistModerationStore } from "../../../stores/CRM/AdminCRM/artists/artistModeration.store"
import { artistFormNotificationsStore } from "../../../stores/CRM/ArtistCRM/artistFormNotifications.store"

export function saveArtistForm(request: ArtistFormSaveRequest, id?: number): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'ARTIST' ? '/crm/artist/form/' : `/crm/admin/artist_forms/${id}/`
  const prepared = {
    techRiders: request.techRiders?.filter(Boolean).map(techRider => techRider?.id),
    photoSite: request.photoSite?.id || null,
    photoSiteHeader: request.photoSiteHeader?.id || null,
    photoSocial: request.photoSocial?.id || null,
    photoAffiche: request.photoAffiche?.id || null,
    membersCount: request.membersCount ? parseInt(request.membersCount) : null,
    members: request.members?.map((member) => {
      const requestMember = {...member}
      requestMember.birthday = 
        isDateValid(requestMember.birthday) ? (
          moment(requestMember.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
        ) : ''
      return requestMember
    })
  }
  const serializedData = Serialize({...request, ...prepared}, ArtistFormSaveRequest)
  
  artistFormSaveStore.setIsLoading(true)

  return api.put(
    url,
    serializedData
  ).then(() => {
    artistFormSaveStore.setIsLoading(false)
    artistFormSaveStore.setSaveTime(moment().toDate())
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    artistFormSaveStore.setIsLoading(false)
    artistFormSaveStore.setErrors(errorResponseData.errors)
    throw error
  })
}

const getArtistFormUrl = (id?: number): string => {
  const userRole = localStorage.getItem('userRole')

  switch (userRole) {
    case 'ARTIST':
      return '/crm/artist/form/'
    case 'ADMIN':
      return `/crm/admin/artist_forms/${id}/`
    case 'EXPERT':
      return `/crm/expert/artist_forms/${id}/`
    default: 
      return `/crm/admin/artist_forms/${id}/`
  }
}

export function getArtistForm(id?: number): Promise<void> {
  const url = getArtistFormUrl(id)
  artistFormGetStore.setIsLoading(true)

  return api.get(
    url
  ).then((res) => {

    const data: ArtistFormGetResponse = Deserialize(res.data, ArtistFormGetResponse)
    const videoUrls: Array<string | undefined> = []
    const audioUrls: Array<string | undefined> = []
    const members: MemberModel[] = []
    const { comments, ...formData } = data

    if (data.members?.length) {
      data.members?.forEach((member) => {
        member.birthday = moment(member.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY')
        members.push(member)
      })
    } else {
      members.push(new MemberModel('', '', '', '', '', '', ''))
    }

    if (data.videoUrls?.length) {
      data.videoUrls.forEach(videoUrl => videoUrls.push(videoUrl))
    }

    while (videoUrls && videoUrls.length < 5) {
      videoUrls.push(undefined)
    }

    if (data.audioUrls?.length) {
      data.audioUrls.forEach(audioUrl => audioUrls.push(audioUrl))
    }

    while (audioUrls.length < 5) {
      audioUrls.push(undefined)
    }

    artistFormGetStore.setFormData({
      ...formData,
      genre: data.genre ? data.genre.label : null,
      videoUrls,
      audioUrls,
      techRiders: data.techRiders || null,
      members: [...members]
    })
    artistFormGetStore.setComments(comments)
    artistFormGetStore.setIsLoading(false)
    artistModerationStore.setFormStatus(data.status)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    artistFormGetStore.setIsLoading(false)
    artistFormGetStore.setErrors(errorResponseData.errors)
  })
}

export function applyForm(request: ArtistFormApplyRequest): Promise<void> {
  artistFormApplyStore.setIsLoading(true)
  const serializedData = Serialize(request, ArtistFormApplyRequest)

  return api.post(
    '/crm/artist/form/apply/',
    serializedData
  ).then(() => {
    artistFormApplyStore.setIsLoading(false)
    artistModerationStore.setFormStatus('MODERATION')
    artistFormNotificationsStore.addNotificationOnApply()
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    artistFormApplyStore.setIsLoading(false)
    artistFormApplyStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function moderateArtist(comment: string, id?: number): Promise<void> {
  artistFormModerateStore.setIsLoading(true)

  return api.post(
    `/crm/admin/artist_forms/${id}/moderate/`,
    {comment}
  ).then(() => {
    artistFormModerateStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    artistFormModerateStore.setIsLoading(false)
    artistFormModerateStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function approveArtist(id?: number): Promise<void> {
  artistFormApproveStore.setIsLoading(true)

  return api.post(
    `/crm/admin/artist_forms/${id}/approve/`
  ).then(() => {
    artistFormApproveStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    artistFormApproveStore.setIsLoading(false)
    artistFormApproveStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function checkForm(id: number): Promise<void> {
  artistFormReadStore.setIsLoading(true)

  return api.post(
    `/crm/admin/artist_forms/${id}/check/`
  ).then(() => {
    artistFormReadStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    artistFormReadStore.setIsLoading(false)
    artistFormReadStore.setErrors(errorResponseData.errors)
    throw error
  })
}