import React from "react"
import { Switch, withRouter, RouteComponentProps, Redirect } from "react-router-dom"
import { PrivateRoute } from "../../PrivateRoute/PrivateRoute"
import { 
  PATH_CRM_ARTISTS,
  PATH_ADMIN_ARTIST_MODERATE,
  PATH_CRM_SCENES, 
  PATH_PLATFORM_MODERATE,
  PATH_EXPERTS,
  PATH_CRM_EXPERT_ADD,
  PATH_EXPERT_PROFILE,
  PATH_FILES,
  PATH_RIDERS_ARTISTS,
  PATH_RIDERS_SCENES,
  PATH_RIDERS_COMMON,
  PATH_ARTISTS_INTERSECTIONS,
  PATH_SELECTION_EXPERTS
} from "../../../const/routes.const"
import AdminCRMLayout from "./AdminCRMLayout/AdminCRMLayout.component"
import ArtistListContainer from "../../../containers/CRM/AdminCRM/ArtistList/ArtistList.container"
import ArtistCRMLayout from "../ArtistCRM/ArtistCRMLayout/ArtistCRMLayout.component"
import ArtistFormContainer from "../../../containers/CRM/ArtistCRM/ArtistForm/ArtistForm.container"
import PlatformListContainer from "../../../containers/CRM/AdminCRM/PlatformList/PlatformList.container"
import PageLayoutComponent from "../../PageLayout/PageLayout.component"
import 
  PlatformModerationComponent from "./PlatformModeration/PlatformModeration.component"
import ExpertListContainer from "../../../containers/CRM/AdminCRM/ExpertList/ExpertList.container"
import AddExpertContainer from "../../../containers/CRM/AdminCRM/AddExpert/AddExpert.container"
import ExpertProfileContainer from "../../../containers/CRM/AdminCRM/ExpertProfile/ExpertProfile.container"
import FilesCategoriesContainer from "../../../containers/CRM/AdminCRM/FilesCategories/FilesCategories.container"
import FilesGroupListContainer from "../../../containers/CRM/AdminCRM/FileGroupList/FilesGroupList.container"
import 
  ArtistsIntersectionsContainer 
from 
  "../../../containers/CRM/AdminCRM/ArtistsIntersections/ArtistsIntersections.container"
import 
  SelectionExpertListContainer 
from 
  "../../../containers/CRM/AdminCRM/SelectionExpertList/SelectionExpertList.container"

class AdminCRM extends React.Component<RouteComponentProps> {
  render(): JSX.Element {
    const userRole = localStorage.getItem('userRole')

    return (
      <>
        {userRole === 'ADMIN' && (
          <Switch>
            <PrivateRoute path={PATH_CRM_ARTISTS}>
              <AdminCRMLayout title="АРТИСТЫ">
                <ArtistListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute path={PATH_CRM_SCENES}>
              <AdminCRMLayout title="ПЛОЩАДКИ">
                <PlatformListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute exact path={`${PATH_ADMIN_ARTIST_MODERATE}/:artistId`}>
              <ArtistCRMLayout>
                <ArtistFormContainer />
              </ArtistCRMLayout>
            </PrivateRoute>

            <PrivateRoute path={`${PATH_PLATFORM_MODERATE}/:platformId`}>
              <PageLayoutComponent>
                <PlatformModerationComponent />
              </PageLayoutComponent>
            </PrivateRoute>

            <PrivateRoute path={PATH_CRM_EXPERT_ADD}>
              <AdminCRMLayout 
                title="ДОБАВЛЕНИЕ ЭКСПЕРТА"
                backRoute={PATH_EXPERTS}
              >
                <AddExpertContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute path={`${PATH_EXPERTS}/:id`}>
              <PageLayoutComponent>
                <ExpertProfileContainer />
              </PageLayoutComponent>
            </PrivateRoute>

            <PrivateRoute path={PATH_EXPERTS}>
              <AdminCRMLayout title="Координаторы">
                <ExpertListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute path={PATH_SELECTION_EXPERTS}>
              <AdminCRMLayout title="Эксперты">
                <SelectionExpertListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute exact path={PATH_RIDERS_ARTISTS}>
              <AdminCRMLayout title="Тех. райдеры артистов" backRoute={PATH_FILES}>
                <FilesGroupListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute exact path={PATH_RIDERS_SCENES}>
              <AdminCRMLayout title="Тех. райдеры сцен" backRoute={PATH_FILES}>
                <FilesGroupListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute exact path={PATH_RIDERS_COMMON}>
              <AdminCRMLayout title="Бытовые райдеры" backRoute={PATH_FILES}>
                <FilesGroupListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute path={PATH_FILES}>
              <AdminCRMLayout title="Файлы" isStaticHeader={true}>
                <FilesCategoriesContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute path={PATH_ARTISTS_INTERSECTIONS}>
              <AdminCRMLayout title="Пересечения по музыкантам">
                <ArtistsIntersectionsContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute>
              <AdminCRMLayout>
                <ArtistListContainer />
              </AdminCRMLayout>
            </PrivateRoute>
          </Switch>
        )}

        {userRole === 'EXPERT' && (
          <Switch>
            <PrivateRoute path={PATH_EXPERT_PROFILE} exact>
              <PageLayoutComponent>
                <ExpertProfileContainer />
              </PageLayoutComponent>
            </PrivateRoute>

            <PrivateRoute path={PATH_CRM_SCENES}>
              <AdminCRMLayout title="ПЛОЩАДКИ">
                <PlatformListContainer />
              </AdminCRMLayout>
            </PrivateRoute>

            <PrivateRoute path={`${PATH_PLATFORM_MODERATE}/:platformId`}>
              <PageLayoutComponent>
                <PlatformModerationComponent />
              </PageLayoutComponent>
            </PrivateRoute>

            <PrivateRoute exact path={`${PATH_ADMIN_ARTIST_MODERATE}/:artistId`}>
              <ArtistCRMLayout>
                <ArtistFormContainer />
              </ArtistCRMLayout>
            </PrivateRoute>

            <Redirect to={PATH_CRM_SCENES} />
          </Switch>
        )}
        
      </>
    )
  }
}

export default withRouter(AdminCRM)
