export const PATH_ROOT = '/'
export const PATH_HIDDEN_REGISTRATION = '/563873d38950b841aebf89c3be1b1a35'
export const PATH_CRM = '/crm'
export const PATH_CRM_ARTISTS = '/artists'
export const PATH_CRM_SCENES = '/scenes'
export const PATH_ARTIST_FORM = '/request'
export const PATH_ARTIST_NOTIFICATIONS = '/notifications'
export const PATH_ADMIN_ARTIST_MODERATE = '/artist-form'

export const PATH_PLATFORM_MODERATE = '/platform'
export const PATH_PLATFORM_FORM = `${PATH_PLATFORM_MODERATE}/:platformId`
export const PATH_PLATFORM_SCENES = `${PATH_PLATFORM_MODERATE}/:platformId/scenes`
export const PATH_PLATFORM_SCENE_FORM = `${PATH_PLATFORM_SCENES}/:sceneId`
export const PATH_PLATFORM_EXPERTS = `${PATH_PLATFORM_MODERATE}/:platformId/experts`
export const PATH_PLATFORM_SCENE_SHEDULE = `${PATH_PLATFORM_SCENES}/:sceneId/shedule`

export const PATH_CRM_ARTISTS_NEW = '/artists/new'
export const PATH_CRM_ARTISTS_REMODERATION = '/artists/remoderation'
export const PATH_CRM_ARTISTS_APPROVED = '/artists/approved'
export const PATH_CRM_ARTISTS_CHECKED = '/artists/checked'

export const PATH_CRM_SCENES_NEW = '/scenes/new'
export const PATH_CRM_SCENES_REMODERATION = '/scenes/remoderation'
export const PATH_CRM_SCENES_APPROVED = '/scenes/approved'
export const PATH_CRM_SCENES_CHECKED = '/scenes/cheked'

export const PATH_EXPERTS = '/experts'
export const PATH_CRM_EXPERT_ADD = '/experts/add'

export const PATH_SELECTION_EXPERTS = '/selection-experts'

export const PATH_EXPERT_PROFILE = '/profile'

export const PATH_FILES = '/files'
export const PATH_RIDERS_ARTISTS = '/files/artists-riders'
export const PATH_RIDERS_SCENES = '/files/scenes-riders'
export const PATH_RIDERS_COMMON = '/files/common-riders'

export const PATH_ARTISTS_INTERSECTIONS = '/artists-intersections'

// selection expert
export const PATH_SELECTION_EXPERT_DASHBOARD = '/dashboard'
export const PATH_SELECTION_EXPERT_EVALUATION = '/evaluation'