import classNames from 'classnames'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import { 
  EvaluateForm
} from '../../../../../models/SelectionExpertsModels/selectionExpertEvaluation.model'
import Button from '../../../../Button/Button.component'

import './style.sass'

interface Props {
  onSubmit: (requestData: EvaluateForm) => void;
  isLoading: boolean;
  error?: string;
}

const block = 'selection-expert-evaluate-form'

const EvaluateFormComponent: FC<Props> = (props) => {
  const { error, isLoading, onSubmit } = props
  const {
    handleSubmit,
    errors,
    control,
    watch,
    formState
  } = useForm<EvaluateForm>()

  const musicRatingField = watch('musicRating')
  const emotionalRatingField = watch('emotionalRating')
  const concertActivitiesRatingField = watch('concertActivitiesRating')

  const getSummary = (): number => {
    const musicRating = !!musicRatingField ? parseInt(musicRatingField) : 0
    const emotionalRating = !!emotionalRatingField ? parseInt(emotionalRatingField) : 0
    const concertActivitiesRating = !!concertActivitiesRatingField ? parseInt(concertActivitiesRatingField) : 0

    return musicRating + emotionalRating + concertActivitiesRating
  }

  const getError = (): string | boolean => {
    const errorFieldType = 
      errors.musicRating?.type || errors.emotionalRating?.type || errors.concertActivitiesRating?.type

    if (errorFieldType === 'inRange') {
      return 'В некоторых полях оценка не вписывается в допустимый диапазон.'
    }

    if (errorFieldType === 'required') {
      return 'Заполнены не все обязательные поля.'
    }

    return false
  }

  return (
    <div className={block}>
      <div className={`${block}__title`}>
        ОЦЕНИТЬ КОЛЛЕКТИВ
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-labels-block`}>
          <div className={`${block}__input-title`}>
            Оцените от 1 (низкий уровень качества) до 10 баллов (высокий уровень)
          </div>
          <div className={`${block}__input-label`}>
            Качество музыкальных произведений во всей его совокупности: инструментальный ряд, 
            текст, мелодия, вокальное исполнение и др.
          </div>
        </div>

        <div className={`${block}__input`}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Это обязательное поле"
              },
              validate: {
                inRange: (value): boolean | string => {
                  const valueInt = parseInt(value)
                  const maxValue = 10
                  const minValue = 1

                  if (valueInt <= maxValue && valueInt >= minValue) {
                    return true
                  } else {
                    return 'Введите число в диапазоне'
                  }
                }
              }
            }}
            control={control} 
            name="musicRating"
            defaultValue={null}
            render={({ onChange, value, name }): JSX.Element => (
              <MaskedInput 
                className={classNames(
                  `${block}__input`,
                  errors.musicRating?.message && `${block}__input--error`
                )}
                onChange={(event): void => onChange(event.target.value)}
                name={name}
                value={value}
                mask={createNumberMask({
                  prefix: '',
                  includeThousandsSeparator: false,
                  allowDecimal: false,
                  integerLimit: 2
                })}
                placeholder={'0'}
              />
            )}
          />
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-labels-block`}>
          <div className={`${block}__input-title`}>
            Оцените от 1 (низкий уровень эмоционального отклика) до 10 баллов (высокий уровнь)
          </div>
          <div className={`${block}__input-label`}>
            Уровень эмоционального отклика члена экспертного совета на представленные произведения.
          </div>
        </div>

        <div className={`${block}__input`}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Это обязательное поле"
              },
              validate: {
                inRange: (value): boolean | string => {
                  const valueInt = parseInt(value)
                  const maxValue = 10
                  const minValue = 1

                  if (valueInt <= maxValue && valueInt >= minValue) {
                    return true
                  } else {
                    return 'Введите число в диапазоне'
                  }
                }
              }
            }}
            control={control} 
            name="emotionalRating"
            defaultValue={null}
            render={({ onChange, value, name }): JSX.Element => (
              <MaskedInput 
                className={classNames(
                  `${block}__input`,
                  errors.emotionalRating?.message && `${block}__input--error`
                )}
                onChange={(event): void => onChange(event.target.value)}
                name={name}
                value={value}
                mask={createNumberMask({
                  prefix: '',
                  includeThousandsSeparator: false,
                  allowDecimal: false,
                  integerLimit: 2
                })}
                placeholder={'0'}
              />
            )}
          />
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-labels-block`}>
          <div className={`${block}__input-title`}>
            Оцените от 0 (отсутствие опыта) до 5 баллов (большой опыт)
          </div>
          <div className={`${block}__input-label`}>
            Опыт концертной деятельности и участия в музыкальных фестивалях.
          </div>
        </div>

        <div className={`${block}__input`}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Это обязательное поле"
              },
              validate: {
                inRange: (value): boolean | string => {
                  const valueInt = parseInt(value)
                  const maxValue = 5
                  const minValue = 0

                  if (valueInt <= maxValue && valueInt >= minValue) {
                    return true
                  } else {
                    return 'Введите число в диапазоне'
                  }
                }
              }
            }}
            control={control} 
            name="concertActivitiesRating"
            defaultValue={null}
            render={({ onChange, value, name }): JSX.Element => (
              <MaskedInput 
                className={classNames(
                  `${block}__input`,
                  errors.concertActivitiesRating?.message && `${block}__input--error`
                )}
                onChange={(event): void => onChange(event.target.value)}
                name={name}
                value={value}
                mask={createNumberMask({
                  prefix: '',
                  includeThousandsSeparator: false,
                  allowDecimal: false,
                  integerLimit: 1
                })}
                placeholder={'0'}
              />
            )}
          />
        </div>
      </div>
      
      <div className={`${block}__summary`}>
        <div className={`${block}__summary-title`}>
          Итого баллов:
        </div>
        <div className={`${block}__summary-amount`}>
          {getSummary()}
        </div>
      </div>

      <div className={`${block}__button`}>
        <Button 
          onClick={handleSubmit(onSubmit)}
          background="purple"
          text="Отправить оценку" 
          isDisabled={isLoading}
        />
      </div>

      {!formState.isValid && (
        <div className={`${block}__error`}>
          {getError()}
        </div>
      )}

      {!!error && (
        <div className={`${block}__error`}>
          {error}
        </div>
      )}
    </div>
  )
}

export default EvaluateFormComponent