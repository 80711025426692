import { action, observable } from "mobx"
import { 
  SelectionExpertArtistGetResponse 
} from "../../../models/SelectionExpertsModels/selectionExpertEvaluation.model"
import { ApiRequestStore } from "../../common/apiRequest.store"

class SelectionExpertArtistGetStore extends ApiRequestStore {
  @observable artist?: SelectionExpertArtistGetResponse
  
  @action
  setArtistFormData(data: SelectionExpertArtistGetResponse): void {
    this.artist = data
  }

  @action
  clearData(): void {
    this.artist = undefined
  }
}

class SelectionExpertEvaluate extends ApiRequestStore {}

export const selectionExpertArtistGetStore: SelectionExpertArtistGetStore = new SelectionExpertArtistGetStore()
export const selectionExpertEvaluateStore: SelectionExpertEvaluate = new SelectionExpertEvaluate()