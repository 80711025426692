import React from 'react'
import { ReactComponent as Bell } from '../../../../assets/icons/bell.svg'
import { ReactComponent as Comment } from '../../../../assets/icons/comment-transparent.svg'
import { 
  ArtistFormNotificationsResponse, FormNotification
} from '../../../../models/ArtistFormNotificationsModels/ArtistFormNotifications.model'

import './ArtistNotifications.style.sass'
import classNames from 'classnames'
import Button from '../../../Button/Button.component'
import moment from 'moment'

interface PropTypes {
  notificationsData?: ArtistFormNotificationsResponse;
  onFormEditClick: () => void;
}

const block = 'artist-notifications'

export default class ArtistNotificationsComponent extends React.Component<PropTypes> {
  private needNotificationHighlight = true

  render(): JSX.Element {
    const { notificationsData, onFormEditClick } = this.props
    const notifications = notificationsData?.notifications
    this.needNotificationHighlight = true

    return (
      <div className={block}>
        <div className={`${block}__body`}>
          <div className={`${block}__title page-title page-title--black`}>
            Уведомления
          </div>

          {notifications && !!notifications.length && (
            <div className={`${block}__notifications`}>
              {notifications.map((notification: FormNotification, index: number) => {
                const notificationJsx = (
                  <div 
                    className={classNames(
                      `${block}__notifications-item`,
                      notification.type === 'NEED_EDITING' && this.needNotificationHighlight && (
                        `${block}__notifications-item--alert`
                      ),
                      notification.type === 'APPROVED' && this.needNotificationHighlight && (
                        `${block}__notifications-item--success`
                      )
                    )}
                    key={`notification-${index}`}
                  >
                    <div className={`${block}__notifications-data`}>
                      <div className={`${block}__notifications-image`}>
                        {notification.type === 'NEED_EDITING' ? (
                          <Comment fill={
                            this.needNotificationHighlight ? "#fff" : "#000"
                          } />
                        ) : (
                          <Bell fill={
                            (notification.type === 'APPROVED' && this.needNotificationHighlight) ? "#fff" : "#000"
                          } />
                        )}
                      </div>
                      <div className={`${block}__notifications-text`}>
                        {notification.text}
                      </div>
                    </div>
                    <div className={`${block}__notifications-date`}>
                      {moment(notification.createdAt).format('DD.MM.YYYY')}
                    </div>
                  </div>
                )

                if (notification.type === 'NEED_EDITING' || notification.type === 'APPROVED') {
                  this.needNotificationHighlight = false
                }

                return notificationJsx
              })}
            </div>
          )}

          {notificationsData?.status !== 'MODERATION' && (
            <div className="margin margin--top-m">
              <Button
                text="Редактировать анкету"
                onClick={onFormEditClick}
                background="purple"
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}