import React from 'react'
import { Form, Field } from 'react-final-form'
import { PerformanceArtistFilterModel } from '../../../../../models/ScenesModels/performances.model'

import './ArtistsFilter.style.sass'
import SelectComponent from '../../../common/Select/Select.component'
import moment from 'moment'
import InputComponent from '../../../common/Input/Input.component'
import { genreRadioButtonsData, GenreRadioButtonsData } from '../../../ArtistCRM/ArtistForm/data.const'
import GenreRadioButtonComponent from '../../../common/GenreRadioButton/GenreRadioButton.component'
import classNames from 'classnames'
import TimePicker from 'rc-time-picker'

interface PropTypes {
  formInitialValues?: PerformanceArtistFilterModel;
  onFilterApply: (values: PerformanceArtistFilterModel) => void;
  onFilterReset: () => void;
  onClose?: () => void;
}

const block = 'performance-artists-filter'
const timelineDays = ['09-17', '09-18', '09-19']

export default class ArtistsFilterComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { formInitialValues, onFilterApply, onFilterReset, onClose } = this.props

    return (
      <div className={block}>
        <Form
          onSubmit={onFilterApply}
          initialValues={formInitialValues || {}}
          render={({
            handleSubmit,
            form
          }): JSX.Element => (
            <>
            <div className={`${block}__body`}>
              <div className={`${block}__close`} onClick={onClose} />
              <div className="form__input-block">
                <div className="form__input-title">
                  Музыкальный жанр
                </div>
                <div className={classNames(
                  "form__input",
                  "row",
                  "row--wrap",
                  `${block}__genre-select`
                )}>
                  {genreRadioButtonsData.map((
                    buttonData: GenreRadioButtonsData
                  ): JSX.Element => (
                    <Field
                      name="genre"
                      type="radio"
                      key={buttonData.value}
                      value={buttonData.value}
                    >
                      {({ input }): JSX.Element => (
                        <div className={'margin margin--top-xxs'}>
                          <GenreRadioButtonComponent
                            checked={input.checked}
                            caption={buttonData.caption}
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            icon={buttonData.icon}
                            type="radio"
                          />
                        </div>
                      )}
                    </Field>
                  ))}
                </div>
              </div>

              <div className="form__input-block margin--top-xs">
                <div className="form__input-title">
                  Количество человек в коллективе
                </div>
                <div className="form__input margin margin--top-xxs">
                  <Field 
                    name="maxMembersCount" 
                    parse={(value: string): string => value.replace(/\D/g, '')}
                  >
                    {({ input }): JSX.Element => (
                      <InputComponent
                        placeholder="Введите число"
                        value={input.value}
                        name={input.name}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="form__input-block margin--top-xs">
                <div className="form__input-title">
                  Дата
                </div>
                <div className="form__input margin--top-xxs">
                  <Field name="freeDate">
                    {({ input }): JSX.Element => (
                      <SelectComponent
                        value={input.value}
                        onChange={input.onChange}
                        placeholder="Выберите дату"
                        options={timelineDays.map(day => ({
                          label: moment(day, 'MM-DD').format('DD.MM'),
                          value: day
                        }))}
                        name={input.name}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="form__input-block margin--top-xs">
                <div className="form__input-title">
                  Свободное время
                </div>
                <div className="row margin--top-xxs row--align-bottom">
                  с
                  <div className={`${block}__timeline-input`}>
                    <Field name="freeStartTime">
                      {({ input }): JSX.Element => (
                        <TimePicker
                          onChange={(momentValue): void => {
                            !!momentValue ? (
                              input.onChange(`${momentValue.format('HH:mm')}:00`)
                            ) : (
                              input.onChange(undefined)
                            )
                          }}
                          className="form-time-picker form-time-picker--xxs"
                          popupClassName="form-time-picker-popup"
                          showSecond={false}
                          minuteStep={30}
                          value={input.value ? moment(input.value, 'HH:mm') : undefined}
                          onClose={(): void => input.onBlur()}
                          placeholder="__:__"
                          focusOnOpen={false}
                          defaultOpenValue={moment('00:00', 'HH:mm')}
                        />
                      )}
                    </Field>
                  </div>
                  по
                  <div className={`${block}__timeline-input`}>
                    <Field name="freeEndTime">
                      {({ input }): JSX.Element => (
                        <TimePicker
                          onChange={(momentValue): void => {
                            !!momentValue ? (
                              input.onChange(`${momentValue.format('HH:mm')}:00`)
                            ) : (
                              input.onChange(undefined)
                            )
                          }}
                          className="form-time-picker form-time-picker--xxs"
                          popupClassName="form-time-picker-popup"
                          showSecond={false}
                          minuteStep={30}
                          value={input.value ? moment(input.value, 'HH:mm') : undefined}
                          onClose={(): void => input.onBlur()}
                          placeholder="__:__"
                          focusOnOpen={false}
                          defaultOpenValue={moment('00:00', 'HH:mm')}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin--top-s">
              <div 
                className={`${block}__button ${block}__button--reset`}
                onClick={(): void => {
                  onFilterReset()
                  form.reset()
                }}
              >
                Сбросить
              </div>
              <div 
                className={`${block}__button ${block}__button--apply`}
                onClick={handleSubmit}
              >
                Применить
              </div>
            </div>
            </>
          )}
        />
      </div>
    )
  }
}