import React from 'react'
import { PATH_RIDERS_ARTISTS, PATH_RIDERS_SCENES, PATH_RIDERS_COMMON } from '../../../../const/routes.const'
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg'

import './FilesCategories.style.sass'

interface PropTypes {
  onCardClick: (route: string) => void;
}

const block = 'files-categories'

const cardsData = [
  {
    title: 'Тех. райдеры артистов',
    route: PATH_RIDERS_ARTISTS
  },
  {
    title: 'Тех. райдеры сцен',
    route: PATH_RIDERS_SCENES
  },
  {
    title: 'Бытовые райдеры',
    route: PATH_RIDERS_COMMON
  }
]

const getDownloadLink = (testUrl: string, productionUrl: string): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return testUrl
    case "production":
      return productionUrl
    case "test":
      return testUrl
    default:
      return productionUrl
  }
}

const filesCardsData = [
  {
    title: 'Полиграфия & Медиа',
    downloadLink: getDownloadLink(
      'https://storage.yandexcloud.net/testumn/crm_reports/polygraphy.xlsx',
      'https://storage.yandexcloud.net/umn/crm_reports/polygraphy.xlsx'
    )
  },
  {
    title: 'Охрана',
    downloadLink: getDownloadLink(
      'https://storage.yandexcloud.net/testumn/crm_reports/security.xlsx',
      'https://storage.yandexcloud.net/umn/crm_reports/security.xlsx'
    )
  },
  {
    title: 'Футболки',
    downloadLink: getDownloadLink(
      'https://storage.yandexcloud.net/testumn/crm_reports/t_shirts.xlsx',
      'https://storage.yandexcloud.net/umn/crm_reports/t_shirts.xlsx'
    )
  },
  {
    title: 'Волонтеры',
    downloadLink: getDownloadLink(
      'https://storage.yandexcloud.net/testumn/crm_reports/volunteers.xlsx',
      'https://storage.yandexcloud.net/umn/crm_reports/volunteers.xlsx'
    )
  }
]

export default class FilesCategoriesComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onCardClick } = this.props

    return (
      <div className={block}>
        <div className="row row--space-between">
          {cardsData.map(card => (
            <div 
              className={`${block}__card`} 
              onClick={(): void => onCardClick(card.route)}
              key={`${card.title}-${card.route}`}
            >
              <div className={`${block}__card-title`}>
                {card.title}
              </div>
            </div>
          ))}
        </div>
        <div className={`row row--space-between ${block}__file-cards`}>
          {filesCardsData.map(card => (
            <div 
              className={`${block}__card`} 
              key={`${card.title}-${card.downloadLink}`}
            >
              <div className={`${block}__card-title`}>
                {card.title}
              </div>
              <a 
                href={card.downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className={`${block}__download`}
              >
                <div className={`${block}__download-icon`}>
                  <Arrow width="15" height="15" fill="#ffffff" />
                </div>
                <div className={`${block}__download-text`}>
                  Скачать
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    )
  }
}