import { ApiRequestStore } from '../../../common/apiRequest.store'
import { action, observable } from 'mobx'
import { PlatformSaveRequest } from '../../../../models/ScenesModels/platform.model'
import { CommentModel } from '../../../../models/ArtistFormModels/artistForm.model'

class PlatformFormSaveStore extends ApiRequestStore {
  @observable saveTime?: Date;

  @action
  setSaveTime(date: Date): void {
    this.saveTime = date
  } 

  @action
  clearData = (): void => {
    this.saveTime = undefined
  }
}

class PlatformFormGetStore extends ApiRequestStore {
  @observable formData?: PlatformSaveRequest;
  @observable comments?: CommentModel[];

  @action
  setFormData(data: PlatformSaveRequest): void {
    this.formData = data
  }

  @action
  setComments(comments: CommentModel[]): void {
    this.comments = comments
  }

  @action
  clearData = (): void => {
    this.formData = undefined
    this.comments = undefined
  }
}

class PlatformFormApplyStore extends ApiRequestStore {}

class PlatformFormModerateStore extends ApiRequestStore {}

class PlatformFormReadStore extends ApiRequestStore {}

class PlatformFormApproveStore extends ApiRequestStore {}

export const platformFormGetStore: PlatformFormGetStore = new PlatformFormGetStore()
export const platformFormSaveStore: PlatformFormSaveStore = new PlatformFormSaveStore()
export const platformFormApplyStore: PlatformFormApplyStore = new PlatformFormApplyStore()
export const platformFormModerateStore: PlatformFormModerateStore = new PlatformFormModerateStore()
export const platformFormReadStore: PlatformFormReadStore = new PlatformFormReadStore()
export const platformFormApproveStore: PlatformFormApproveStore = new PlatformFormApproveStore()