import React from "react"
import classNames from "classnames"
import { ReactComponent as Loader } from "../../assets/icons/button-loading.svg"

import "./Button.style.sass"

interface PropTypes {
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
  text: string;
  isDisabled?: boolean;
  background: "purple" | "pale-blue" | "transparent" | "black";
  isCircle?: boolean;
  children?: React.ReactNode;
  icon?: JSX.Element;
  isLoading?: boolean;
  isTextCentered?: boolean;
}

const block = "button"

export default class Button extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onClick, text, background, isDisabled, icon, isLoading, isTextCentered } = this.props

    return (
      <div
        className={classNames(
          block,
          `${block}--${background}`,
          isDisabled && `${block}--disabled`,
          isLoading && `${block}--loading`,
          isTextCentered && `${block}--text-centered`
        )}
        onClick={(event: React.SyntheticEvent<HTMLElement>): void => {
          !isDisabled && !isLoading && onClick(event)
        }}
      >
        {isLoading ? (
          <div className={`${block}__loader`}>
            <Loader width="30" height="30" fill="#933ec5" />
          </div>
        ) : (
          <>
          {icon && (
            <div className={`${block}__icon`}>
              {icon}
            </div>
          )}
          
          {text}
          </>
        )}

      </div>
    )
  }
}
