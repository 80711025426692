import React from 'react'
import CommentInputContainer from '../../../../containers/CRM/AdminCRM/AdminApproveSidebar/CommentInput.container'
import Button from '../../../Button/Button.component'
import { 
  CommentModel, 
  artistFormStatuses, 
  ArtistFormSaveRequest 
} from '../../../../models/ArtistFormModels/artistForm.model'
import moment from 'moment'
import DownloadFormComponent from './DownloadForm/DownloadForm.component'
import { PlatformStatus } from '../../../../models/ScenesModels/platformList.model'
import { RequestError } from '../../../../models/common/error.model'
import ErrorLabelComponent from '../../common/ErrorLabel/ErrorLabel.component'
import Sticky from 'react-sticky-el'
import { getUserRole } from '../../../../utils/localStorage'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { UserRole } from '../../../../models/common/user.model'

import './AdminApproveSidebar.style.sass'

interface PropTypes {
  onModerate: () => void;
  onApprove: () => void;
  onCheckForm: () => void;
  isModerateActive: boolean;
  isApproveActive: boolean;
  comments?: CommentModel[];
  formStatus: artistFormStatuses | PlatformStatus;
  formData?: ArtistFormSaveRequest;
  submittionErrors?: RequestError[];
  commentInputTitle: string;
  isModerateLoading: boolean;
  isApproveLoading: boolean;
  isCheckLoading: boolean;
  onTabChange: (tabIndex: number) => void;
  activeTabIndex: number;
}

export const sidebarTabs = {
  comments: {
    index: 0,
    title: 'КОММЕНТАРИИ'
  },
  hints: {
    index: 1,
    title: 'ПОДСКАЗКИ'
  }
}

const authorNames: {
  [key in UserRole]: string
} = {
  ADMIN: 'Модератор',
  ARTIST: 'Артист',
  EXPERT: 'Координатор',
  SELECTION_EXPERT: 'Эксперт'
}

const block = "admin-approve-sidebar"

export default class AdminApproveSidebarComponent extends React.Component<PropTypes> {  
  render(): JSX.Element {
    const { 
      onModerate, 
      onApprove, 
      isModerateActive, 
      isApproveActive, 
      comments, 
      formStatus, 
      onCheckForm, 
      submittionErrors,
      commentInputTitle,
      isModerateLoading,
      isApproveLoading,
      isCheckLoading,
      onTabChange,
      activeTabIndex
    } = this.props

    const userRole = getUserRole()
    const slicedComments = comments?.slice(0, 2)
    
    return (
      <Tabs 
        className={block}
        selectedTabClassName={`${block}__tabs-item--selected`}
        selectedIndex={activeTabIndex || 0} 
        onSelect={onTabChange}
      >
        <TabList className={`${block}__tabs`}>
          <Tab className={`${block}__tabs-item`}>
            {sidebarTabs.comments.title}
          </Tab>
          <Tab className={`${block}__tabs-item`}>
            {sidebarTabs.hints.title}
          </Tab>
        </TabList>
        <TabPanel>
          {userRole === 'ADMIN' && formStatus !== 'EDITING' && (
            <>
            <div className={`${block}__comment-input-section`}>
              <div className={`${block}__comment-input`}>
                <div className={`${block}__comment-input-title`}>
                  {commentInputTitle}
                </div>
                <div className="margin margin--top-xs">
                  <CommentInputContainer />
                </div>
                <div className="margin margin--top-xs">
                  <Button
                    onClick={onModerate}
                    text="Отправить на доработку"
                    background="purple"
                    isDisabled={!isModerateActive}
                    isLoading={isModerateLoading}
                  />
                </div>
              </div>
    
              {(formStatus === 'APPROVED' || formStatus === 'CHECKED') ? (
                <div className="margin--top-xs">
                  <Button 
                    text="Вычитано" 
                    onClick={onCheckForm} 
                    background="purple" 
                    isDisabled={!isApproveActive} 
                    isLoading={isCheckLoading}
                  />
                </div>
              ) : (
                <div className="margin margin--top-s">
                  <Button
                    onClick={onApprove}
                    text="Одобрить заявку"
                    background="purple"
                    isDisabled={!isApproveActive}
                    isLoading={isApproveLoading}
                  />
                </div>
              )}
    
              {submittionErrors && submittionErrors.length && (
                submittionErrors.map((error, index): JSX.Element => (
                  <ErrorLabelComponent text={error.text} key={`${error.code}-${index}`} />
                ))
              )}
            </div>
            
            {this.props.formData && (
              <div className="margin margin--top-s">
                <DownloadFormComponent formData={this.props.formData} />
              </div>
            )}
            </>
          )}


          <div className={`${block}__comments-section`}>
            <div className={`${block}__comments-title`}>
              Последние комментарии:
            </div>
            <div className={`${block}__comments`}>
              {slicedComments && 
                !!slicedComments.length ? slicedComments.map((comment: CommentModel, index: number) => (
                <Sticky 
                  key={`artist-top-comment-${index}`}
                  boundaryElement=".artist-form__sidebar"
                  stickyStyle={{
                    top: 120,
                    zIndex: 10
                  }}
                  topOffset={-120}
                  disabled={index !== 0}
                  style={{minHeight: 113}}
                >
                  <div className={`${block}__comments-item`} key={`admin-comment-${index}`}>
                    <div className={`${block}__comments-item-author`}>
                      {authorNames[comment.author.role]}
                    </div>
                    <div className={`${block}__comments-item-date`}>
                      {moment(comment.createdAt).format('DD.MM.YYYY')}
                    </div>
                    <div className={`${block}__comments-item-text`}>
                      {comment.text}
                    </div>
                  </div>
                </Sticky>
              )) : (
                <div className={`${block}__no-comments`}>Комментариев нет</div>
              )}
            </div>
            {/* {comments && comments.length > 2 && (
              <div className="margin margin--top-xs">
                <Button
                  onClick={(): void => console.log('more comments')}
                  text="Еще комментарии"
                  background="purple"
                />
              </div>
            )} */}
          </div>
        </TabPanel>
        <TabPanel />
      </Tabs>
    )
  }
}