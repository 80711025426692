import React from 'react'

import { Page, Text, View, Document, Font, Image, pdf, Link } from '@react-pdf/renderer'
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg'
import stolzlMedium from '../../../../../assets/fonts/stolzl-medium.woff'
import stolzlRegular from '../../../../../assets/fonts/stolzl-regular.woff'
import { ArtistFormSaveRequest } from '../../../../../models/ArtistFormModels/artistForm.model'
import hiphop from '../../../../../assets/images/artist-form/hip-hop.png'
import hiphopActive from '../../../../../assets/images/artist-form/hip-hop-active.png'
import electro from '../../../../../assets/images/artist-form/electro.png'
import electroActive from '../../../../../assets/images/artist-form/electro-active.png'
import pop from '../../../../../assets/images/artist-form/pop.png'
import popActive from '../../../../../assets/images/artist-form/pop-active.png'
import classic from '../../../../../assets/images/artist-form/classic.png'
import classicActive from '../../../../../assets/images/artist-form/classic-active.png'
import folk from '../../../../../assets/images/artist-form/folk.png'
import folkActive from '../../../../../assets/images/artist-form/folk-active.png'
import rock from '../../../../../assets/images/artist-form/rock.png'
import rockActive from '../../../../../assets/images/artist-form/rock-active.png'
import jazz from '../../../../../assets/images/artist-form/jazz.png'
import jazzActive from '../../../../../assets/images/artist-form/jazz-active.png'
import metal from '../../../../../assets/images/artist-form/metal.png'
import metalActive from '../../../../../assets/images/artist-form/metal-active.png'
import facebook from '../../../../../assets/images/artist-form/fb.png'
import instagram from '../../../../../assets/images/artist-form/instagram.png'
import vkontakte from '../../../../../assets/images/artist-form/vk.png'
import soundcloud from '../../../../../assets/images/artist-form/soundcloud.png'
import document from '../../../../../assets/images/artist-form/document.png'
import tiktok from '../../../../../assets/images/artist-form/tiktok.png'
import { saveAs } from 'file-saver'
import { pdfFormStyles } from './styles'
import Button from '../../../../Button/Button.component'

Font.register({ family: 'StolzlRegular', src: stolzlRegular })
Font.register({ family: 'StolzlMedium', src: stolzlMedium })

interface PropTypes {
  formData: ArtistFormSaveRequest;
}

const makeBreaks = (input: string, maxLength: number): string => {
  const regex = new RegExp(`.{1,${maxLength}}`, 'g')
  const splittedString = input.match(regex) || ['']

  const stringWithBreaks = splittedString?.map((partOfString: string): string => (
    `${partOfString}\u000a`
  ))

  return stringWithBreaks.join('')
}

const genreData = [
  {
    value: 'hiphop',
    caption: 'Хип-Хоп',
    icon: hiphop,
    activeIcon: hiphopActive
  },
  {
    value: 'electro',
    caption: 'Электроника',
    icon: electro,
    activeIcon: electroActive
  },
  {
    value: 'pop',
    caption: 'Поп',
    icon: pop,
    activeIcon: popActive
  },
  {
    value: 'classic',
    caption: 'Классика',
    icon: classic,
    activeIcon: classicActive
  },
  {
    value: 'folk',
    caption: 'Фолк',
    icon: folk,
    activeIcon: folkActive
  },
  {
    value: 'rock',
    caption: 'Рок',
    icon: rock,
    activeIcon: rockActive
  },
  {
    value: 'jazz',
    caption: 'Джаз',
    icon: jazz,
    activeIcon: jazzActive
  },
  {
    value: 'metal',
    caption: 'Метал',
    icon: metal,
    activeIcon: metalActive
  },
  {
    value: 'dj',
    caption: 'DJ',
    icon: metal,
    activeIcon: metalActive
  }
]

export default class DownloadFormComponent extends React.Component<PropTypes> {
  private _isMounted = false;

  componentDidMount(): void {
    this._isMounted = true
  }

  componentWillUnmount(): void {
    this._isMounted = false
  }

  // hack to prevent lag on props change, that fires rerender and document recreation
  downLoadPdf = async (): Promise<void> => {
    const blob = await pdf((
      this.createDocument()
    )).toBlob()
    saveAs(blob, `${this.props.formData.name}.pdf`)
  }

  createDocument = (): JSX.Element => {
    const data = this.props.formData
    const styles = pdfFormStyles

    return (
      <Document 
        onRender={(): void => {
          if (this._isMounted) {
            this.setState({ isLoading: false })
          }
        }}
      >
        <Page size="A4" style={styles.page}>
          <View style={styles.form}>
            <View style={styles.section}>
              <Text style={styles.title}>1. ОСНОВНАЯ ИНФОРМАЦИЯ</Text>
              <View style={styles.formBlock}>
                <View wrap={false} style={[styles.inputBlock, styles.noMargin]}>
                  <Text style={styles.inputTitle}>
                    * Название коллектива / имя музыканта
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>
                      {data.name}
                    </Text>
                  </View>
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Музыкальный жанр
                  </Text>
                  <View style={styles.row}>
                    {genreData.map((genre, index: number) => (
                      <View style={styles.genre} key={`pdf-genre-${index}`}>
                        <View style={styles.genreIcon}>
                          <Image source={
                            data.genre === genre.value ? genre.activeIcon : genre.icon
                          } />
                        </View>
                        <View style={styles.genreCaptionContainer}>
                          <Text style={styles.genreCaption}>
                            {genre.caption}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Музыкальное направление
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>
                      {data.subgenre}
                    </Text>
                  </View>
                </View>

                <View wrap={false} style={styles.row}>

                  <View style={[styles.inputBlock, styles.rowInput]}>
                    <Text style={styles.inputTitle}>
                      * Страна
                    </Text>
                    <View style={styles.input}>
                      <Text style={styles.inputText}>
                        {data.country}
                      </Text>
                    </View>
                  </View>

                  <View style={[styles.inputBlock, styles.rowInput]}>
                    <Text style={styles.inputTitle}>
                      * Город
                    </Text>
                    <View style={styles.input}>
                      <Text style={styles.inputText}>
                        {data.city}
                      </Text>
                    </View>
                  </View>

                </View>

                <View wrap={false} style={styles.row}>

                  <View style={[styles.inputBlock, styles.rowInput]}>
                    <Text style={styles.inputTitle}>
                      * Email
                    </Text>
                    <View style={styles.input}>
                      <Text style={styles.inputText}>
                        {makeBreaks(data.contactEmail || '', 29)}
                      </Text>
                    </View>
                  </View>

                  <View style={[styles.inputBlock, styles.rowInput]}>
                    <Text style={styles.inputTitle}>
                      * Телефон
                    </Text>
                    <View style={styles.input}>
                      <Text style={styles.inputText}>
                        {data.contactPhone}
                      </Text>
                    </View>
                  </View>

                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Контактное лицо
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>
                      {data.contactName}
                    </Text>
                  </View>
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Описание музыкального коллектива
                  </Text>
                  <View style={[styles.input, styles.textarea]}>
                    <Text style={styles.inputText}>
                      {data.description}
                    </Text>
                  </View>
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Музыкальный проект в социальных сетях
                  </Text>
                  <View style={styles.row}>
                    <View style={[styles.input, styles.rowInput, styles.inputWithImage]}>
                      <View style={styles.inputImage}>
                        <Image source={facebook} />
                      </View>
                      <Text style={styles.inputText}>
                        {makeBreaks(data.facebookUrl || '', 26)}
                      </Text>
                    </View>
                    <View style={[styles.input, styles.rowInput, styles.inputWithImage]}>
                      <View style={styles.inputImage}>
                        <Image source={instagram} />
                      </View>
                      <Text style={styles.inputText}>
                        {makeBreaks(data.instagramUrl || '', 26)}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={[styles.input, styles.rowInput, styles.inputWithImage]}>
                      <View style={styles.inputImage}>
                        <Image source={vkontakte} />
                      </View>
                      <Text style={styles.inputText}>
                        {makeBreaks(data.vkontakteUrl || '', 26)}
                      </Text>
                    </View>
                    <View style={[styles.input, styles.rowInput, styles.inputWithImage]}>
                      <View style={styles.inputImage}>
                        <Image source={tiktok} />
                      </View>
                      <Text style={styles.inputText}>
                        {makeBreaks(data.tiktokUrl || '', 26)}
                      </Text>
                    </View>
                  </View>
                </View>

              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>2. СОСТАВ КОЛЛЕКТИВА</Text>
              <View style={styles.formBlock}>
                <View wrap={false} style={[styles.inputBlock, styles.noMargin]}>
                  <Text style={styles.inputTitle}>
                    * Количество участников в коллективе
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>
                      {data.membersCount}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.formBlock}>
                {data.members && data.members.map((member, index) => {
                  if (!member) return <></>

                  return (
                    <View key={`member--${index}`}>
                      {index !== 0 && (
                        <View style={styles.separator} />
                      )}

                      <View wrap={false} style={[styles.inputBlock, index === 0 ? styles.noMargin : {}]}>
                        <Text style={styles.inputTitle}>
                          * ФИО
                        </Text>
                        <View style={[styles.input, styles.rowInput]}>
                          <Text style={styles.inputText}>
                            {data.members && data.members[index].name}
                          </Text>
                        </View>
                      </View>

                      <View wrap={false} style={styles.row}>

                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>
                            * Дата рождения
                          </Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].birthday}
                            </Text>
                          </View>
                        </View>

                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>
                            * Размер футболки
                          </Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].tShirtSize}
                            </Text>
                          </View>
                        </View>

                      </View>

                      <View wrap={false} style={styles.row}>
                        <View style={[styles.input, styles.rowInput, styles.inputWithImage, styles.marginedInput]}>
                          <View style={styles.inputImage}>
                            <Image source={facebook} />
                          </View>
                          <Text style={styles.inputText}>
                            {makeBreaks((data.members && data.members[index].facebookUrl) || '', 26)}
                          </Text>
                        </View>
                        <View style={[styles.input, styles.rowInput, styles.inputWithImage, styles.marginedInput]}>
                          <View style={styles.inputImage}>
                            <Image source={instagram} />
                          </View>
                          <Text style={styles.inputText}>
                            {makeBreaks((data.members && data.members[index].instagramUrl) || '', 26)}
                          </Text>
                        </View>
                      </View>

                      <View wrap={false} style={styles.row}>
                        <View style={[styles.input, styles.rowInput, styles.inputWithImage, styles.marginedInput]}>
                          <View style={styles.inputImage}>
                            <Image source={vkontakte} />
                          </View>
                          <Text style={styles.inputText}>
                            {makeBreaks((data.members && data.members[index].vkontakteUrl) || '', 26)}
                          </Text>
                        </View>
                        <View style={[styles.input, styles.rowInput, styles.inputWithImage, styles.marginedInput]}>
                          <View style={styles.inputImage}>
                            <Image source={soundcloud} />
                          </View>
                          <Text style={styles.inputText}>
                            {makeBreaks((data.members && data.members[index].twitterUrl) || '', 26)}
                          </Text>
                        </View>
                      </View>
                      
                    </View>
                  )
                })}
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>3. МЕДИАМАТЕРИАЛЫ</Text>
              <View style={styles.formBlock}>

                <View wrap={false} style={[styles.inputBlock, styles.noMargin]}>
                  <Text style={styles.inputTitle}>
                    * Фотографии
                  </Text>
                  
                  <View style={styles.row}>
                    <View style={styles.photoInput}>
                      <Text style={styles.photoInputTitle}>
                        На сайт
                      </Text>
                      <View style={styles.photoInputImageWrapper}>
                        <Image
                          style={styles.photoInputImage}
                          source={
                            {
                              uri: data.photoSite?.url || '',
                              method: 'GET',
                              headers: {},
                              body: ''
                            }
                          }
                        />
                      </View>
                    </View>

                    <View style={styles.photoInput}>
                      <Text style={styles.photoInputTitle}>
                        В шапку сайта
                      </Text>
                      <View style={styles.photoInputImageWrapper}>
                        <Image
                          style={styles.photoInputImage}
                          source={
                            {
                              uri: data.photoSiteHeader?.url || '',
                              method: 'GET',
                              headers: {},
                              body: ''
                            }
                          }
                          allowDangerousPaths={true}
                        />
                      </View>
                    </View>

                    <View style={styles.photoInput}>
                      <Text style={styles.photoInputTitle}>
                        Для соц.сетей
                      </Text>
                      <View style={styles.photoInputImageWrapper}>
                        <Image
                          style={styles.photoInputImage}
                          source={
                            {
                              uri: data.photoSocial?.url || '',
                              method: 'GET',
                              headers: {},
                              body: ''
                            }
                          }
                        />
                      </View>
                    </View>

                    <View style={styles.photoInput}>
                      <Text style={styles.photoInputTitle}>
                        Для афиши
                      </Text>
                      <View style={styles.photoInputImageWrapper}>
                        <Image
                          style={styles.photoInputImage}
                          source={
                            {
                              uri: data.photoAffiche?.url || '',
                              method: 'GET',
                              headers: {},
                              body: ''
                            }
                          }
                        />
                      </View>
                    </View>
                  </View>

                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    Ссылка на видео
                  </Text>
                  {data.videoUrls.map((videoUrl, index) => (
                    <View style={styles.input} key={`pdfVideoUrl-${index}`}>
                      <Text style={styles.inputText}>
                        {makeBreaks(videoUrl || '', 70)}
                      </Text>
                    </View>
                  ))}
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    Ссылка на вашу музыку
                  </Text>
                  {data.audioUrls.map((audioUrl, index) => (
                    <View style={styles.input} key={`pdfVideoUrl-${index}`}>
                      <Text style={styles.inputText}>
                        {makeBreaks(audioUrl || '', 70)}
                      </Text>
                    </View>
                  ))}
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Технические райдеры
                  </Text>
                  <View>
                    {data.techRiders && data.techRiders.map((techRider, index) => (
                      <View style={styles.techRider} key={`pdfTechRider-${index}`}>
                        <Image style={styles.techRiderImage} source={document} />
                        <Link src={techRider?.url || ''} style={styles.techRiderText}>
                          {techRider?.name}
                        </Link>
                      </View>
                    ))}
                    
                  </View>
                </View>

              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>4. ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</Text>
              <View style={styles.formBlock}>

                <View wrap={false} style={[styles.inputBlock, styles.noMargin]}>
                  <Text style={styles.inputTitle}>
                    Ссылки на СМИ в вашем городе для анонсирования вашего участия в фестивале
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>
                      {makeBreaks(data.localMediaUrl || '', 70)}
                    </Text>
                  </View>
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Ссылка на промо-пост с анонсом участия в фестивале UMN
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>
                      {makeBreaks(data.promoPostUrl || '', 70)}
                    </Text>
                  </View>
                </View>

              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  render(): JSX.Element {
    return (
      <Button
        onClick={this.downLoadPdf}
        background="purple"
        text="Скачать анкету"
        icon={<ArrowDown fill="#fff" width="18.5" height="20" />}
      />
    )
  }
}