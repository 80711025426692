import React from 'react'
import { ListSceneModel } from '../../../../../models/ScenesModels/scenesList.model'
import CircleButtonComponent from '../../../common/CircleButton/CircleButton.component'
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/icon-plus.svg"
import ModalComponent from '../../../../common/Modal/Modal.component'
import InputComponent from '../../../common/Input/Input.component'
import Button from '../../../../Button/Button.component'
import LoaderComponent from '../../../../common/Loader/Loader.component'

import './ScenesList.style.sass'

interface PropTypes {
  scenes?: ListSceneModel[];
  onSceneAddClick: () => void;
  onSceneCreate: () => void;
  newSceneName: string;
  isScenesLoading: boolean;
  isModalVisible: boolean;
  onSceneNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSceneCreating: boolean;
  onModalToggle: () => void;
  onSceneClick: (id: number, name: string) => void;
}

const block = 'scenes-list'

export default class ScenesListComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { 
      scenes, 
      onSceneAddClick, 
      isScenesLoading, 
      newSceneName, 
      isModalVisible,
      onSceneNameChange,
      onSceneCreate,
      isSceneCreating,
      onModalToggle,
      onSceneClick
    } = this.props
    const userRole = localStorage.getItem('userRole')

    return (
      <div className={`${block}`}>
        <div className="container">
          {isScenesLoading ? (
            <div className={`${block}__loading`}>
              <LoaderComponent width={100} height={100} />
            </div>
          ) : (
            <>
            <div className="page-title page-title--black">
              СЦЕНЫ
            </div>
            {userRole === 'ADMIN' && (
              <div className={`${block}__add-block margin--top-m`}>
                <CircleButtonComponent onClick={onSceneAddClick}>
                  <PlusIcon fill="#FFFFFF" />
                </CircleButtonComponent>
                <div className={`${block}__add-block-text`}>
                  Добавить новую сцену
                </div>
              </div>
            )}
            <div className={`${block}__scenes`}>
              {scenes?.map((scene, index): JSX.Element => (
                <div 
                  className={`${block}__scenes-item`} 
                  key={`list-scene-${index}`} 
                  onClick={(): void => onSceneClick(scene.id, scene.name)}
                >
                  <div className={`${block}__scenes-item-image`}></div>
                  <div className={`${block}__scenes-item-name`}>
                    {scene.name}
                  </div>
                </div>
              ))}

              {!scenes?.length && (
                <div>
                  Список сцен пуст
                </div>
              )}
            </div>
  
            <ModalComponent isVisible={isModalVisible} title="Создать новую сцену" onModalClose={onModalToggle}>
              <div className="margin margin--top-s">
                <InputComponent 
                  name="scene-name"
                  placeholder="Введите название сцены"
                  value={newSceneName}
                  onChange={onSceneNameChange}
                />
              </div>
              <div className="margin margin--top-s margin--auto margin--bottom-0">
                <Button
                  onClick={onSceneCreate}
                  text="Создать сцену"
                  isDisabled={!newSceneName.length}
                  background="purple"
                  isLoading={isSceneCreating}
                />
              </div>
            </ModalComponent>
            </>
          )}

        </div>

      </div>
    )
  }
}