import React from 'react'
import PlatformExpertsListContainer from '../../../../../containers/CRM/AdminCRM/PlatformModeration/PlatformExpertsList'
import ModalComponent from '../../../../common/Modal/Modal.component'
import InputComponent from '../../../common/Input/Input.component'
import Button from '../../../../Button/Button.component'
import { SceneSaveRequest } from '../../../../../models/ScenesModels/scene.model'
import { withTypes, Field } from 'react-final-form'
import { Element, scroller } from 'react-scroll'
import LoaderComponent from '../../../../common/Loader/Loader.component'
import { fieldRequired, composeValidators } from '../../../../../const/validators.const'
import InputLabelComponent from '../../../ArtistCRM/ArtistForm/InputLabel/InputLabel.component'
import classNames from 'classnames'
import { sceneGenreButtonsData, SceneGenreButtonsData } from './data.const'
import GenreRadioButtonComponent from '../../../common/GenreRadioButton/GenreRadioButton.component'
import arrayMutators from 'final-form-arrays'
import TextAreaComponent from '../../../common/TextArea/TextArea.component'
import UploadFileContainer from '../../../../../containers/CRM/common/UploadFile.container'
import { FieldArray } from 'react-final-form-arrays'
import TimePicker from 'rc-time-picker'
import { AddElementComponent } from '../../../common/AddElement/AddElement.component'
import moment from 'moment'
import SelectComponent from '../../../common/Select/Select.component'
import shallowequal from 'shallowequal'

import './SceneForm.style.sass'
import 'rc-time-picker/assets/index.css'
import { ValidationErrors } from 'final-form'

interface PropTypes {
  onModalToggle: () => void;
  onExpertAdd: () => void;
  expertAdditionError?: string;
  isModalVisible: boolean;
  newExpertEmail: string;
  onExpertEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isExpertAdding: boolean;
  onSubmit: (values: SceneSaveRequest) => void;
  isSceneLoading: boolean;
  isExpertsListLoading: boolean;
  formInitialValues?: SceneSaveRequest;
  showValidationErrors: boolean;
  onShowErrors: () => void;
  isSaveLoading: boolean;
  shouldShowSaveSucceeded: boolean;
  isFirstEdit: boolean;
}

const { Form } = withTypes<SceneSaveRequest>()
const block = 'scene-form'

export default class SceneFormComponent extends React.Component<PropTypes> {
  private formValues?: SceneSaveRequest;
  private lastSubmitValues?: SceneSaveRequest;
  private timeOut?: number;

  componentDidMount(): void {
    if (this.props.isFirstEdit) {
      this.timeOut = window.setTimeout(this.autoSaveInterval, 1000 * 30)
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeOut)
  }

  autoSaveInterval = (): void => {
    if (!shallowequal(this.formValues, this.lastSubmitValues) && this.formValues) {
      this.props.onSubmit(this.formValues)
      this.lastSubmitValues = this.formValues
    }
    
    this.timeOut = window.setTimeout(this.autoSaveInterval, 1000 * 30)
  }
  
  scrollToFirstError = (errors: ValidationErrors): void => {
    const errorFields = Object.keys(errors)

    scroller.scrollTo(errorFields[0], {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -230
    })
  }

  render(): JSX.Element {
    const {
      onModalToggle,
      isModalVisible,
      newExpertEmail,
      onExpertEmailChange,
      onExpertAdd,
      isExpertAdding,
      onSubmit,
      isSceneLoading,
      isExpertsListLoading,
      formInitialValues,
      expertAdditionError,
      isSaveLoading,
      shouldShowSaveSucceeded
    } = this.props
    const userRole = localStorage.getItem('userRole')

    return (
      <div className={block}>
        <div className="container">
          {(isSceneLoading || isExpertsListLoading) && !formInitialValues ? (
            <LoaderComponent width={100} height={100} />
          ) : (
            <>
            {userRole === 'ADMIN' && (
              <>
              <div className={`${block}__add-block margin--top-s`}>
                <AddElementComponent onClick={onModalToggle} text="Добавить эксперта" />
              </div>
              <div className="margin--top-s">
                <PlatformExpertsListContainer isSceneExperts={true} />
              </div>
              <ModalComponent 
                isVisible={isModalVisible} 
                title="Прикрепить нового эксперта" 
                onModalClose={onModalToggle}
              >
                <div className="margin margin--top-s">
                  <InputComponent 
                    name="new-expert-email"
                    placeholder="Введите email эксперта"
                    value={newExpertEmail}
                    onChange={onExpertEmailChange}
                    error={expertAdditionError}
                  />
                </div>
                <div className="margin margin--top-s margin--auto margin--bottom-0">
                  <Button
                    onClick={onExpertAdd}
                    text="Добавить эксперта"
                    isDisabled={!newExpertEmail.length}
                    background="purple"
                    isLoading={isExpertAdding}
                  />
                </div>
              </ModalComponent>
              </>
            )}

            <Form
              onSubmit={(values): void => onSubmit(values)}
              initialValues={formInitialValues}
              mutators={{
                ...arrayMutators
              }}
              validate={(values): ValidationErrors => {
                const errors: ValidationErrors = {}

                if (values.genres && !values.genres.length) {
                  errors.genres = 'Необходимо выбрать как минимум 1 жанр'
                }

                return errors
              }}
              render={({
                values,
                handleSubmit,
                errors,
                active,
                form: {
                  mutators: { push, remove }
                },
                dirtySinceLastSubmit
              }): JSX.Element => {
                this.formValues = values

                return (
                  <form 
                    className={`${block}__form form margin--top-l`}
                    onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {            
                      event.preventDefault()
                      event.stopPropagation()
                      onSubmit(values)
                    }}
                    id="scene-form"
                  >
                    <div>
                      <div className="form__title">
                        Информация о сцене
                      </div>
                      <div className="form__form-block">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Творческое название сцены
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="name" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                  <div className="form__input-label">
                                    <InputLabelComponent isFocused={meta.active}>
                                      Например: &quot;Ночь музыки в кинотеатре Салют&quot;
                                    </InputLabelComponent>
                                  </div>
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block margin margin--top-s">
                          <div className={classNames(
                            "form__input-title",
                            this.props.showValidationErrors && errors.genres && "form__input-title--error"
                          )}>
                            * Музыкальный жанр
                          </div>
                          <div className="form__input">
                            <FieldArray name="genres">
                              {({ fields }): JSX.Element[] => (
                                fields.map((name): JSX.Element => (
                                  <div key={`genres[${name}]`} />
                                ))
                              )}
                            </FieldArray>

                            
                            <Element name="genres">
                              <div className="row">
                                {sceneGenreButtonsData.map((
                                  buttonData: SceneGenreButtonsData
                                ): JSX.Element => (
                                  <Field
                                    name={buttonData.value}
                                    type="checkbox"
                                    key={buttonData.value}
                                    value={buttonData.value}
                                  >
                                    {({ input }): JSX.Element => (
                                      <div className={'margin margin--top-xxs'}>
                                        <GenreRadioButtonComponent
                                          checked={
                                            values.genres 
                                              && values.genres.length > 0 && values.genres.includes(buttonData.value)
                                          }
                                          caption={buttonData.caption}
                                          name={input.name}
                                          value={input.value}
                                          onChange={values.genres && values.genres.length < 2
                                            && !values.genres.includes(buttonData.value) ? (
                                            (): void => push('genres', buttonData.value)
                                          ) : (
                                            (): void => (
                                              values.genres.includes(buttonData.value) && (
                                                remove('genres', values.genres.indexOf(buttonData.value))
                                              )
                                            )
                                          )}
                                          icon={buttonData.icon}
                                          type="checkbox"
                                        />
                                        <div className="form__input-label">
                                          <InputLabelComponent>
                                            Можно выбрать максимум 2 жанра
                                          </InputLabelComponent>
                                        </div>
                                      </div>
                                    )}
                                  </Field>
                                ))}
                              </div>
                            </Element>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            * Музыкальное направление сцены
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="subgenre" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                  <div className="form__input-label">
                                    <InputLabelComponent isFocused={meta.active}>
                                      Например: &quot;Поп-панк&quot; или &quot;электроника-хаус&quot;
                                    </InputLabelComponent>
                                  </div>
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-row margin--top-s">
                          <div className="form__input-block">
                            <div className="form__input-title">
                              * Дата начала работы
                            </div>
                            <div className="form__input margin margin--top-xxs">
                              <Field name="date" validate={fieldRequired}>
                                {({ input }): JSX.Element => (
                                  <Element name={input.name}>
                                    <SelectComponent
                                      value={input.value}
                                      onChange={input.onChange}
                                      placeholder="Выберите дату"
                                      options={[
                                        {label: '24.06', value: '06-24'},
                                        {label: '25.06', value: '06-25'},
                                        {label: '26.06', value: '06-26'}
                                      ]}
                                      name={input.name}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>

                        <div className="form__input-row margin--top-s">
                          <div className="form__input-block">
                            <div className="form__input-title">
                              * Время начала работы
                            </div>
                            <div className="form__input margin margin--top-xxs">
                              <Field name="startTime" validate={fieldRequired}>
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <TimePicker
                                      onChange={(momentValue): void => (
                                        !!momentValue ? (
                                          input.onChange(`${momentValue.format('HH:mm')}:00`)
                                        ) : (
                                          input.onChange(undefined)
                                        )
                                      )}
                                      className={classNames(
                                        'form-time-picker',
                                        meta.touched && meta.error && 'form-time-picker--error'
                                      )}
                                      popupClassName="form-time-picker-popup"
                                      showSecond={false}
                                      minuteStep={30}
                                      value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                      onOpen={(): void => input.onFocus()}
                                      onClose={(): void => input.onBlur()}
                                      placeholder="Выберите время"
                                      focusOnOpen={false}
                                      defaultOpenValue={moment('00:00', 'HH:mm')}
                                    />
                                    {meta.touched && meta.error && (
                                      <div className="form-time-picker__error">
                                        {meta.error}
                                      </div>
                                    )}
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="form__input-block">
                            <div className="form__input-title">
                              * Время конца работы
                            </div>
                            <div className="form__input margin margin--top-xxs">
                              <Field name="endTime" validate={fieldRequired}>
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <TimePicker
                                      onChange={(momentValue): void => (
                                        !!momentValue ? (
                                          input.onChange(`${momentValue.format('HH:mm')}:00`)
                                        ) : (
                                          input.onChange(undefined)
                                        )
                                      )}
                                      className={classNames(
                                        'form-time-picker',
                                        meta.touched && meta.error && 'form-time-picker--error'
                                      )}
                                      popupClassName="form-time-picker-popup"
                                      showSecond={false}
                                      minuteStep={30}
                                      value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                      onOpen={(): void => input.onFocus()}
                                      onClose={(): void => input.onBlur()}
                                      placeholder="Выберите время"
                                      focusOnOpen={false}
                                      defaultOpenValue={moment('00:00', 'HH:mm')}
                                    />
                                    {meta.touched && meta.error && (
                                      <div className="form-time-picker__error">
                                        {meta.error}
                                      </div>
                                    )}
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            * Концепция площадки
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field
                              name="description"
                              validate={composeValidators(
                                fieldRequired,
                                value => value.length < 300 || value.length > 1000 ? (
                                  'Описание должно быть от 300 до 1000 символов'
                                ) : undefined
                              )}
                            >
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <TextAreaComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                  <div className="form__input-label">
                                    <InputLabelComponent isFocused={meta.active}>
                                      Опишите для СМИ, какая идея лежит в основе мероприятия, 
                                      что объединяет всех артистов<br/> (От 300 до 1000 знаков).
                                    </InputLabelComponent>
                                  </div>
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className={classNames(
                            "form__input-title",
                            (this.props.showValidationErrors && (
                              errors.photoSite || errors.photoSite2 || errors.photoSiteHeader
                            )) && "form__input-title--error"
                          )}>
                            * Фотографии
                          </div>
                          <div className={classNames(
                            `form__images-row`,
                            `${block}__images-row`
                          )}>
                            <div className="form__input margin margin--top-xs">
                              <Field name="photoSite" validate={fieldRequired}>
                                {({ input }): JSX.Element => (
                                  <Element name={input.name}>
                                    <UploadFileContainer
                                      nameForStore={input.name}
                                      title="На сайт"
                                      fileTypes={["image/jpeg", "image/png"]}
                                      onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                      uploadedFile={values.photoSite ? values.photoSite : undefined}
                                      onFileDelete={(): void => {
                                        input.onChange(null)
                                      }}
                                      showFileName={false}
                                      minWidth={600}
                                      minHeight={400}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                            <div className="form__input margin margin--top-xs">
                              <Field name="photoSite2" validate={fieldRequired}>
                                {({ input }): JSX.Element => (
                                  <Element name={input.name}>
                                    <UploadFileContainer
                                      nameForStore={input.name}
                                      title="&nbsp;"
                                      fileTypes={["image/jpeg", "image/png"]}
                                      onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                      uploadedFile={values.photoSite2 ? values.photoSite2 : undefined}
                                      onFileDelete={(): void => {
                                        input.onChange(null)
                                      }}
                                      showFileName={false}
                                      minWidth={600}
                                      minHeight={400}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                            <div className="form__input margin margin--top-xs">
                              <Field name="photoSiteHeader" validate={fieldRequired}>
                                {({ input }): JSX.Element => (
                                  <Element name={input.name}>
                                    <UploadFileContainer
                                      nameForStore={input.name}
                                      title="В шапку сайта"
                                      fileTypes={["image/jpeg", "image/png"]}
                                      onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                      uploadedFile={values.photoSiteHeader ? values.photoSiteHeader : undefined}
                                      onFileDelete={(): void => {
                                        input.onChange(null)
                                      }}
                                      showFileName={false}
                                      minWidth={2880}
                                      minHeight={1100}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>

                            <div className={classNames(
                              "form__input-label",
                              `${block}__photo-input-label`
                            )}>
                              <InputLabelComponent>
                                На сайт: горизонтальная фотография - <span>600 х 400 px;</span><br/>
                                В шапку сайта: горизонтальная - <span>2880 х 1100 px;</span>
                              </InputLabelComponent>
                            </div>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Логотипы партнеров площадки
                          </div>

                          <div className={classNames(
                            `form__images-row`,
                            `${block}__images-row`
                          )}>
                            <FieldArray name="partnerLogos" >
                              {({ fields }): JSX.Element[] => (
                                fields.map((name, index): JSX.Element => (
                                  <div className={classNames(
                                    'form__input',
                                    index < 4 ? 'margin--top-xs' : 'margin--top-xxs'
                                  )} key={`partner-logo-${index}`}>
                                    <Field name={`partnerLogos[${index}]`}>
                                      {({ input }): JSX.Element => (
                                        <Element name={input.name}>
                                          <UploadFileContainer
                                            nameForStore={`partner-logo-${index}`}
                                            fileTypes={[".cdr", ".ai", ".png"]}
                                            onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                            uploadedFile={values.partnerLogos[index]}
                                            onFileDelete={(): void => {
                                              input.onChange(null)
                                            }}
                                            showFileName={false}
                                            minWidth={20}
                                            minHeight={20}
                                          />
                                        </Element>
                                      )}
                                    </Field>
                                  </div>
                                ))
                              )}
                            </FieldArray>
                            <div className={classNames(
                              "form__input-label margin--top-xs"
                            )}>
                              <InputLabelComponent>
                                Допустимые форматы: cdr, ai, png.
                              </InputLabelComponent>
                            </div>
                          </div>
                        </div>

                        <div className="form__input-title margin--top-s">
                          Количество:
                        </div>
                        <div className="form__input-row margin--top-xs">
                          <div className="form__input-block">
                            <div className="form__input-title">
                              Афиш А4
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.postersA4">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="form__input-block">
                            <div className="form__input-title">
                              Афиш А3
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.postersA3">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                              <div className={classNames(
                                "form__input-label"
                              )}>
                                <InputLabelComponent
                                  isFocused={
                                    active ? (
                                      active as string === 'numbers.postersA0'
                                      || active as string === 'numbers.postersA1'
                                      || active as string === 'numbers.postersA2'
                                      || active as string === 'numbers.postersA3'
                                      || active as string === 'numbers.postersA4'
                                      || active as string === 'numbers.banners'
                                      || active as string === 'numbers.flyersA6'
                                      || active as string === 'numbers.stretchingBanners'
                                      || active as string === 'numbers.tableTentsA5'
                                      || active as string === 'numbers.tableTentsA6'
                                    ) : false
                                  }
                                >
                                  Eсли вы уже заполняли этот раздел на странице информации о площадке,
                                  то не этот блок не нуждается в заполнении.
                                </InputLabelComponent>
                              </div>
                            </div>
                          </div>

                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Афиш А2
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.postersA2">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>
                          
                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Афиш А1
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.postersA1">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>
                          
                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Афиш А0
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.postersA0">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>
                          
                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Баннеров
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.banners">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>
                          
                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Флаеров А6
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.flyersA6">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Растяжек
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.stretchingBanners">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Тейбл-тентов А5
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.tableTentsA5">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Тейбл-тентов А6
                            </div>
                            <div className="form__input margin--top-xxs">
                              <Field name="numbers.tableTentsA6">
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                        </div>

                      </div>

                      <div className={`margin margin--top-m ${block}__save`}>
                        <Button
                          text="Сохранить"
                          background="purple"
                          onClick={(): void => {
                            this.scrollToFirstError(errors)
                            this.props.onShowErrors()
                            handleSubmit()
                          }}
                          isLoading={isSaveLoading}
                        />
                        {!dirtySinceLastSubmit && (
                          <div className={classNames(
                            'page-text',
                            'page-text--green',
                            `${block}__saved-label`,
                            shouldShowSaveSucceeded && `${block}__saved-label--visible`
                          )}>
                            Сохранено
                          </div>
                        )}
                      </div>



                    </div>
                  </form>
                )
              }}
            />
            </>
          )}
          
        </div>
      </div>
    )
  }
}