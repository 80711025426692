import React from 'react'
import RegisterFormContainer from '../RegisterForm/RegisterForm.container'
import AuthLayoutComponent from '../../components/AuthLayout/AuthLayout.component'
import AuthInfoComponent from '../../components/AuthInfo/AuthInfo.component'
import LoginFormContainer from '../LoginForm/LoginForm.container'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PATH_HIDDEN_REGISTRATION } from '../../const/routes.const'

interface State {
  isRegisterState: boolean;
}

interface AuthInfoStateData {
  title: string;
  text: string;
  buttonText: string;
}

interface AuthInfoData {
  registerState: AuthInfoStateData;
  loginState: AuthInfoStateData;
}

const authInfoData: AuthInfoData = {
  registerState: {
    title: 'АВТОРИЗАЦИЯ',
    text: 'Войдите в свой аккаунт, если вы уже регистрировались на подачу заявки на фестиваль',
    buttonText: 'Войти'
  },
  loginState: {
    title: 'ПОДАЙТЕ ЗАЯВКУ',
    text: 'Зарегистрируйтесь в системе, чтобы подать новую заявку на участие в Ural Music Night',
    buttonText: 'Зарегистрироваться'
  }
}

class AuthLayoutContainer extends React.Component<RouteComponentProps, State> {

  state: State = {
    isRegisterState: true
  }

  toggleAuthLayout = (): void => {
    this.setState((prevState: State) => ({
      isRegisterState: !prevState.isRegisterState
    }))
  }

  render(): JSX.Element {
    const { isRegisterState } = this.state
    const hasRegister = this.props.location.pathname.indexOf(PATH_HIDDEN_REGISTRATION) >= 0
    
    return(
      <AuthLayoutComponent hasRegister={hasRegister}>
        {isRegisterState && hasRegister && <RegisterFormContainer />}
        {hasRegister ? (
          <AuthInfoComponent 
            title={isRegisterState ? authInfoData.registerState.title : authInfoData.loginState.title}
            text={isRegisterState ? authInfoData.registerState.text : authInfoData.loginState.text}
            buttonText={isRegisterState ? authInfoData.registerState.buttonText : authInfoData.loginState.buttonText}
            onClick={this.toggleAuthLayout}
          />
        ) : (
          <LoginFormContainer />
        )}
        {!isRegisterState && <LoginFormContainer />}
      </AuthLayoutComponent>
    )
  }
}

export default withRouter(AuthLayoutContainer)